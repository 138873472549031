import { useEffect, useRef, useState } from 'react';

export const NotAvailable = () => {
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    timer.current = setTimeout(() => {
      setMessage('Data is not available');
    }, 2000);

    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, []);

  return <>{message}</>;
};
