import { FC, useCallback, useEffect, useState } from 'react';
import { ILegalFolderItem } from '../interface';

import { useLazyQuery, useQuery } from '@apollo/client';
import { FolderOpen as FolderOpenIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { blue } from '@mui/material/colors';
import cn from 'classnames';
import LoadMore from 'components/LoadMore/LoadMore';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import paths from 'constants/paths';
import { GET_ARCHIVE_FILES, GET_ARCHIVE_FILES_COUNT } from 'graphql/legalFolders/archiveFiles';
import { archiveFileCount } from 'graphql/legalFolders/types/archiveFileCount';
import {
  archiveFiles,
  archiveFiles_contract_archiveFiles,
} from 'graphql/legalFolders/types/archiveFiles';
import { ArchiveFileSortableColumn, Order } from 'graphql/legalFolders/types/graphql-types';
import { FileDarkIcon } from 'images/icons';
import { debounce } from 'lodash';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useHistory, useParams } from 'react-router-dom';
import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';
import { markSearchString } from 'utils/reformat';
import s from './style.module.scss';

interface ITableSectionUrlParams {
  id: string;
  tab: string;
  archiveFileId: string;
}

export interface ILegalFolderArchiveProps {
  node: ILegalFolderItem;
  search: string;
  parentLoading?: boolean;
  myTask: boolean;
}

export const LegalFolderArchive: FC<ILegalFolderArchiveProps> = ({
  node,
  search,
  parentLoading,
  myTask,
}) => {
  const { archiveFileId } = useParams<ITableSectionUrlParams>();

  const history = useHistory();
  const { confirmRequest } = useFormChangedDialogContext();
  const [documents, setDocuments] = useState<archiveFiles_contract_archiveFiles[]>([]);

  const count = useQuery<archiveFileCount>(GET_ARCHIVE_FILES_COUNT, {
    variables: {
      filter: { partyIds: node.partyIds },
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [loadDocuments, { called, data, loading, error, refetch }] = useLazyQuery<archiveFiles>(
    GET_ARCHIVE_FILES,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    if (!loading && !error && data && data.contract_archiveFiles.length) {
      setDocuments((old) => {
        const ids = old.map(({ id }) => id);
        return [...old, ...data.contract_archiveFiles.filter(({ id }) => !ids.includes(id))];
      });
    }
  }, [data, loading, error]);

  const load = useCallback(
    (skip: number, take: number) => {
      if (node.partyIds.length) {
        const variables = {
          skip,
          take,
          sort: [{ column: ArchiveFileSortableColumn.BLOB_NAME, order: Order.ASC }],
          filter: { partyIds: node.partyIds },
        };

        if (called) {
          refetch!(variables);
        } else {
          loadDocuments({ variables });
        }
      }
    },
    [loadDocuments, refetch, called, node]
  );

  const loadMore = useCallback(async () => {
    const { data: countData, loading: countLoading, error: countError } = count;
    if (!countError && !countLoading && countData) {
      if (countData.contract_archiveFileCount > documents.length) {
        await load(documents.length, 5);
      }
    }
  }, [load, count, documents]);

  useEffect(() => {
    load(0, 5);
  }, [load]);

  const onViewArchiveFile = useCallback(
    (item: archiveFiles_contract_archiveFiles) => {
      history.push(
        paths.treeViewClient.TREEVIEW_LEGAL_FOLDER_ARCHIVE.replace(':archiveFileId', item.id)
          .replace(':id', node.id)
          .replace(':tab', 'pre-lms')
      );
    },
    [history, node]
  );

  const onView = useCallback(
    (item: archiveFiles_contract_archiveFiles) => () => {
      confirmRequest!((confirmed) => {
        confirmed && onViewArchiveFile!(item);
      });
    },
    [onViewArchiveFile, confirmRequest]
  );

  const onConfirmedViewArchiveFileDetailsPage = useCallback(
    (item: archiveFiles_contract_archiveFiles) => {
      history.push(paths.client.ARCHIVE_FILE_DETAILS.replace(':archiveFileId', item.id));
    },
    [history]
  );

  const onViewArchiveFileDetailsPage = useCallback(
    (item: archiveFiles_contract_archiveFiles) => () => {
      confirmRequest!((confirmed) => {
        confirmed && onConfirmedViewArchiveFileDetailsPage!(item);
      });
    },
    [onConfirmedViewArchiveFileDetailsPage, confirmRequest]
  );

  const onConfirmedLegalFolderArchiveTableSelect = useCallback(() => {
    history.push(
      paths.treeViewClient.TREEVIEW_LEGAL_FOLDER_TAB.replace(':id', node.id).replace(
        ':tab',
        'pre-lms-table'
      )
    );
  }, [history, node]);

  const onLegalFolderArchiveTableSelect = useCallback(() => {
    confirmRequest!((confirmed) => {
      confirmed && onConfirmedLegalFolderArchiveTableSelect!();
    });
  }, [onConfirmedLegalFolderArchiveTableSelect, confirmRequest]);

  return documents && documents.length ? (
    <div className={s.archiveFiles}>
      <div key="PF" style={{ display: 'flex', alignContent: 'center', marginBottom: '5px' }}>
        <div
          onClick={debounce(onLegalFolderArchiveTableSelect, DEBOUNCE_TIMEOUT)}
          style={{ cursor: 'pointer' }}
        >
          <IconButton size="small" style={{ color: blue[700] }}>
            <FolderOpenIcon fontSize="small"></FolderOpenIcon>
          </IconButton>
        </div>
        <div style={{ marginTop: '5px' }}>
          <div style={{ display: 'flex', marginTop: 'auto', marginBottom: 'auto' }}>Pre-LMS:</div>
        </div>
      </div>
      <LoadingOverlay spinner active={loading && !parentLoading} text="Loading your content...">
        {documents.map((item) => {
          return (
            <div className={s.archiveFileRow} key={item.id}>
              <div
                onClick={debounce(onViewArchiveFileDetailsPage(item), DEBOUNCE_TIMEOUT)}
                style={{ cursor: 'pointer' }}
                className={s.icon}
              >
                <FileDarkIcon className={s.documentIcon}></FileDarkIcon>
              </div>
              <div
                onClick={debounce(onView(item), DEBOUNCE_TIMEOUT)}
                style={{ cursor: 'pointer', wordBreak: 'break-all', wordWrap: 'break-word' }}
                className={cn(
                  s.projectTitle,
                  {
                    [s.showActive]: archiveFileId === item.id,
                  },
                  s.separator
                )}
              >
                {markSearchString(item.originalFilename, search)}
              </div>
            </div>
          );
        })}
        {count.data?.contract_archiveFileCount &&
        count.data?.contract_archiveFileCount > documents?.length ? (
          <div className={s.archiveFileRow}>
            <div className={s.projectTitle}>
              <LoadMore loadMore={loadMore} autoLoadDisabled={true} />
            </div>
          </div>
        ) : undefined}
      </LoadingOverlay>
    </div>
  ) : (
    <></>
  );
};
