import { ApolloError, useLazyQuery } from '@apollo/client';
import { IOrderByColumn } from 'components/ui/Table/Table';
import { DEFAULT_ROWS_PER_PAGE } from 'constants/config';

import { useEffect, useState } from 'react';

const MAX_PAGE = 1000;

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface IPageLoadParams {
  orderByColumns?: IOrderByColumn[] | undefined;
  page: number;
  rowsPerPage: number;
  filter?: any;
  filterValues?: any;
  withLimitedAccess?: boolean;
}

export interface IUseAllDataLoaderProps<T, TFilter> {
  initFilter?: any;
  overridePageLoadParams?: any;
  GET_QUERY: any;
  getItems: (data: any) => T[];
  initRowsPerPage?: number;
  filterToAPI: (filterValues: any) => TFilter;
}

export interface IUseAllDataLoaderValue {
  items: any;
  loading: boolean;
  error: ApolloError | undefined;
  pageLoadParams: any;
  isAllLoaded: boolean;
}

export const useAllDataLoader = <
  itemsList extends unknown,
  IItem extends unknown,
  TFilter extends unknown
>({
  initFilter,
  overridePageLoadParams,
  GET_QUERY,
  getItems,
  initRowsPerPage,
  filterToAPI,
}: IUseAllDataLoaderProps<IItem, TFilter>): IUseAllDataLoaderValue => {
  const [items, setItems] = useState<any[]>([]);
  const [loadingPage, setLoadingPage] = useState(-1);
  const [isAllLoaded, setAllLoaded] = useState(false);

  const [pageLoadParams, setPageLoadParams] = useState<IPageLoadParams>({
    page: 0,
    filter: initFilter,
    rowsPerPage: initRowsPerPage || DEFAULT_ROWS_PER_PAGE,
  });

  const [loadItems, { called, data, loading, error, refetch }] = useLazyQuery<itemsList>(
    GET_QUERY,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    if (overridePageLoadParams) {
      setPageLoadParams((initial) => ({
        ...initial,
        ...overridePageLoadParams,
        filter: { ...initial.filter, ...overridePageLoadParams?.filter },
      }));
    }
  }, [overridePageLoadParams]);

  useEffect(() => {
    const { rowsPerPage, page, orderByColumns, filter, withLimitedAccess } = pageLoadParams;
    if (loadingPage < page) {
      setLoadingPage(page);
      const variables = {
        take: rowsPerPage,
        skip: page * rowsPerPage,
        sort: orderByColumns?.length
          ? orderByColumns.map(({ order, orderBy }) => ({ order, column: orderBy }))
          : undefined,
        filter: { ...filter },
        withLimitedAccess,
      };

      if (called) {
        refetch!(variables);
      } else {
        loadItems({ variables });
      }
    }
  }, [loadItems, refetch, called, pageLoadParams, loadingPage]);

  useEffect(() => {
    if (!data && !loading && error) {
      setItems([]);
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (!loading && data) {
      const newItems = getItems(data);
      if (newItems.length) {
        const { rowsPerPage, page } = pageLoadParams;
        setItems((old) => [...old, ...newItems]);
        if (newItems.length === rowsPerPage && page < MAX_PAGE) {
          setPageLoadParams((old) => ({ ...old, page: old.page + 1 }));
        } else {
          setAllLoaded(true);
        }
      } else {
        setAllLoaded(true);
      }
    }
  }, [data, loading, getItems, pageLoadParams]);

  return {
    items,
    loading: loading,
    pageLoadParams,
    error: error,
    isAllLoaded,
  };
};
