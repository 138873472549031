import { ClearAllOutlined as ClearAllOutlinedIcon } from '@mui/icons-material';
import { Box, Button, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import { StickyHeaderTable } from 'components/ui/Table/StickyHeaderTable';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { demacProjectFoldersWithoutProjectsTableHead } from 'constants/projectFoldersWithoutProjectsTable';
import { useUI } from 'contexts/UiContext';
import { useUsersAutocompleteDataHook } from 'hooks/usersAutoCompleteDataHook';
import { debounce } from 'lodash';
import { FC, useEffect, useMemo } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { apolloErrorHandler } from 'utils/apolloErrorHandler';
import { useComponentContext } from './AllProjectFoldersTableContext';
import { ExportToExcel } from './components/ExportToExcel/ExportToExcel';

export interface IAllProjectFoldersTableViewProps {
  title?: string;
  onReady?: () => void;
  printView?: boolean;
}

export const AllProjectFoldersTableView: FC<IAllProjectFoldersTableViewProps> = ({
  onReady,
  printView,
  title,
}) => {
  const { addSnackbar } = useUI();
  const {
    totalItems,
    projectFolders,
    loadPage,
    pageLoadParams,
    loading,
    onFilterChange,
    filterApplied,
    clearAllFilters,
    myTask,
    setMyTask,
    onProjectFolderSelect,
    filterOptions,
    filterValues,
    error,
  } = useComponentContext();

  useEffect(() => {
    if (!!onReady && !loading && projectFolders) {
      onReady();
    }
  }, [loading, projectFolders, onReady]);

  useEffect(() => {
    if (!loading && !!error) {
      apolloErrorHandler(addSnackbar!)(error);
    }
  }, [loading, error, addSnackbar]);

  const exportPageLoadParams = useMemo(() => {
    return {
      ...pageLoadParams,
      filter: { ...pageLoadParams.filter, onlyCurrentUserDocuments: myTask },
    };
  }, [myTask, pageLoadParams]);

  const ownerNamesProps = useUsersAutocompleteDataHook({
    search: filterValues['ownerNames'],
  });

  const extendedProjectFoldersWithoutProjectsTableHead = useMemo(() => {
    demacProjectFoldersWithoutProjectsTableHead.find(
      (item) => item.id === 'ownerNames'
    )!.filterProps = ownerNamesProps;

    return demacProjectFoldersWithoutProjectsTableHead;
  }, [ownerNamesProps]);

  return (
    <LoadingOverlay spinner active={loading && !printView} text="Loading your content...">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: 'flex', alignItems: 'center' }}
              order={{ xs: 1 }}
            >
              {title ? (
                <div style={{ paddingLeft: '0.875rem', paddingTop: '0.875rem' }}>
                  <Typography variant="body2" className="label-title-nocase">
                    {title}
                  </Typography>
                </div>
              ) : undefined}
            </Grid>

            <Grid item xs={12} sm={4} order={{ xs: 3, sm: 2 }}>
              {filterApplied ? (
                <Box display="flex" justifyContent="center" mt={2}>
                  <Button
                    onClick={debounce(clearAllFilters, DEBOUNCE_TIMEOUT)}
                    variant="outlined"
                    size="small"
                    endIcon={<ClearAllOutlinedIcon />}
                  >
                    Clear Filters
                  </Button>
                </Box>
              ) : undefined}
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: 'flex', justifyContent: 'end' }}
              order={{ xs: 2, sm: 3 }}
            >
              <FormControlLabel
                style={{ display: 'none' }}
                control={
                  <Switch
                    color="primary"
                    checked={myTask}
                    onChange={() => {
                      setMyTask!(!myTask);
                    }}
                  ></Switch>
                }
                label="My Task"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box
            style={{
              borderRadius: '1px',
              boxShadow: '0px 2px 5px #00000030',
              border: '1px solid #00000030',
            }}
          >
            <StickyHeaderTable
              totalItems={totalItems}
              dataCells={projectFolders}
              headCells={extendedProjectFoldersWithoutProjectsTableHead}
              loadPage={loadPage}
              handleSelect={onProjectFolderSelect}
              filterOptions={filterOptions}
              filterValues={filterValues}
              onFilterChange={onFilterChange}
              initRowsPerPage={pageLoadParams.rowsPerPage}
              printView={printView}
              initOrderByColumns={pageLoadParams.orderByColumns}
              initPage={pageLoadParams.page}
              paginationSideComponent={
                <ExportToExcel
                  pageLoadParams={exportPageLoadParams}
                  totalItems={totalItems}
                ></ExportToExcel>
              }
            />
          </Box>
        </Grid>
      </Grid>
    </LoadingOverlay>
  );
};
