import React, { useEffect } from 'react';

export const DocusignCompletePage = () => {
  useEffect(() => {
    window.parent.postMessage('docusign_complete', '*'); // You can specify a more secure origin instead of '*'
  }, []);

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>DocuSign Session Complete</h1>
    </div>
  );
};
