import React, { FC, useMemo } from 'react';
import { DocumentsTemplate } from 'template/Documents/DocumentsTemplate';
import {
  IDocumentsTableHookLoadParams,
  SortOrder,
} from 'template/TreeTable/components/AllDocumentsTable/documentsTableHook';
import { useLegalFolder } from 'template/TreeTable/components/Breadcrumbs/legalFolderHook';
import { useProjectFolder } from 'template/TreeTable/components/Breadcrumbs/projectFolderHook';

export const Documents: FC = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const legalFolderId = params.get('lf');
  const projectFolderId = params.get('pf');

  const { projectFolderName, loading: loadingProjectFolderName } = useProjectFolder({
    id: projectFolderId || undefined,
  });

  const { legalFolderName, loading: loadingLegalFolderName } = useLegalFolder({
    id: legalFolderId || undefined,
  });

  const initPageLoadParams: Partial<IDocumentsTableHookLoadParams> | undefined =
    projectFolderId || legalFolderId
      ? {
          orderByColumns: [{ order: SortOrder.DESC, orderBy: 'CURRENT_USER_ACTION_PENDING' }],
        }
      : undefined;

  const overridePageLoadParams = useMemo(() => {
    return !!projectFolderName || !!legalFolderName
      ? {
          filterValues: {
            legalFolder:
              legalFolderId && legalFolderName
                ? { id: legalFolderId, name: legalFolderName }
                : undefined,
            projectFolder:
              projectFolderId && projectFolderName
                ? { id: projectFolderId, name: projectFolderName }
                : undefined,
          },
          filter: {
            legalFolderIds: legalFolderId ? [legalFolderId] : undefined,
            containerIds: projectFolderId ? [projectFolderId] : undefined,
          },
        }
      : undefined;
  }, [projectFolderId, projectFolderName, legalFolderId, legalFolderName]);

  if (loadingProjectFolderName || loadingLegalFolderName) return <></>;

  return (
    <DocumentsTemplate
      overridePageLoadParams={overridePageLoadParams}
      initPageLoadParams={initPageLoadParams}
      tableStorageKey={projectFolderId || legalFolderId ? 'customAllDocumentsTable' : undefined}
    ></DocumentsTemplate>
  );
};
