import React, { FC, useCallback } from 'react';

import { IHeadCell } from '../Table/components/TableHead/TableHead';
import { IOrderByColumn } from '../Table/Table';
import { FiltersList } from './components/FiltersList/FiltersList';
export interface IFilterFormProps {
  headCells: IHeadCell[];
  loadPage?: (
    orderByColumns: IOrderByColumn[] | undefined,
    page: number,
    rowsPerPage: number
  ) => void;
  filterOptions: any;
  filterValues?: any;
  onFilterChange: (filterValues: any) => boolean;
  initRowsPerPage: number;
}

export const FilterForm: FC<IFilterFormProps> = ({
  loadPage,
  headCells,
  filterOptions,
  filterValues,
  onFilterChange,
  initRowsPerPage,
}) => {
  const handleFilterChange = useCallback(
    (filterValues: any) => {
      if (onFilterChange!(filterValues)) {
        loadPage && loadPage(undefined, 0, initRowsPerPage);
      }
    },
    [onFilterChange, loadPage, initRowsPerPage]
  );

  return (
    <div>
      <FiltersList
        headCells={headCells}
        filterOptions={filterOptions}
        filterValues={filterValues}
        onFilterChange={handleFilterChange}
      ></FiltersList>
    </div>
  );
};
