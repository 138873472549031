import React, { createContext, FC, useContext, useEffect } from 'react';
import { DocumentViewTab } from './components/DocumentsView/DocumentsContext';
import { ProjectFoldersViewTab } from './components/ProjectFoldersView/ProjectFoldersContext';

export interface IContextState {
  activeTab: ActiveTab;
  activeDocumentTab: DocumentViewTab;
  activeProjectFoldersTab: ProjectFoldersViewTab;
  projectFolderId?: string;
  legalFolderId?: string;
  projectDocumentId?: string;
  newProjectDocumentTs?: number;
}

export interface IContextActions {
  setActiveTab: (tab: ActiveTab) => void;
  setDocumentViewTab: (tab: DocumentViewTab) => void;
  setProjectFoldersViewTab: (tab: ProjectFoldersViewTab) => void;
}

const initialState: IContextState = {
  activeTab: 'D',
  activeDocumentTab: 'list',
  activeProjectFoldersTab: 'list-pf',
};

const ComponentContext = createContext<IContextState & Partial<IContextActions>>(initialState);

export type ActiveTab = 'D' | 'PF' | 'LF' | 'PRE-LMS' | 'PRE-LMS-TABLE';

interface IProviderProps {
  projectFolderId?: string;
  legalFolderId?: string;
  projectDocumentId?: string;
  newProjectDocumentTs?: number;
  showTab: ActiveTab;
  showDocumentTab: DocumentViewTab;
  showProjectFoldersTab: ProjectFoldersViewTab;
  setActiveTab: (setTab: ActiveTab) => void;
  setDocumentViewTab: (tab: DocumentViewTab) => void;
  setProjectFoldersViewTab: (tab: ProjectFoldersViewTab) => void;
  children: any;
}

export const Provider: FC<IProviderProps> = ({
  children,
  legalFolderId,
  projectFolderId,
  projectDocumentId,
  newProjectDocumentTs,
  showTab,
  setActiveTab,
  setDocumentViewTab,
  setProjectFoldersViewTab,
  showDocumentTab,
  showProjectFoldersTab,
}) => {
  useEffect(() => {
    setActiveTab(showTab);
  }, [showTab, setActiveTab]);

  return (
    <ComponentContext.Provider
      value={{
        activeTab: showTab,
        setActiveTab,
        projectFolderId,
        legalFolderId,
        projectDocumentId,
        newProjectDocumentTs,
        setDocumentViewTab,
        setProjectFoldersViewTab,
        activeDocumentTab: showDocumentTab,
        activeProjectFoldersTab: showProjectFoldersTab,
      }}
    >
      {children}
    </ComponentContext.Provider>
  );
};

export const useComponentContext = () => useContext(ComponentContext);
