import { Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import s from './style.module.scss';
import { paths } from 'constants/index';
import { useComponentContext } from '../../../TableSection/TableSectionContext';
import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';
export interface IFolderTypeTabsProps {
  selectedView: string;
  maxLevel: number;
  setSelectedView: any;
}

const allTabs = [
  {
    label: 'Legal folder',
    value: ['LF', 'PRE-LMS', 'PRE-LMS-TABLE'],
  },
  {
    label: 'Project folder(s)',
    value: ['PF'],
  },
  {
    label: 'Documents',
    value: ['D'],
  },
];
export const FolderTypeTabs: FC<IFolderTypeTabsProps> = ({
  selectedView,
  maxLevel,
  setSelectedView,
}) => {
  const { confirmRequest } = useFormChangedDialogContext();
  const history = useHistory();
  const visibleTabs = allTabs; // useMemo(() => allTabs.slice(-maxLevel), [maxLevel]);
  const { projectFolderId, legalFolderId } = useComponentContext();
  let index = 0;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Tabs
      variant={matches ? 'fullWidth' : 'scrollable'}
      // scrollButtons={matches ? 'auto' : 'on'}
      scrollButtons={false}
      value={selectedView}
      onChange={(e, val) => {
        confirmRequest!((confirmed) => {
          if (!confirmed) {
            return;
          }
          if (val === 'D' && !!projectFolderId) {
            history.push(
              paths.treeViewClient.TREEVIEW_PROJECT_FOLDER_TAB.replace(
                ':id',
                projectFolderId
              ).replace(':tab', 'D')
            );
          } else {
            if (val === 'PF' && !projectFolderId && legalFolderId) {
              history.push(
                paths.treeViewClient.TREEVIEW_LEGAL_FOLDER_PROJECT_FOLDERS.replace(
                  ':id',
                  legalFolderId
                )
              );
            } else {
              setSelectedView(val);
            }
          }
        });
      }}
      className={s.folderTypeTabs}
      TabIndicatorProps={{ style: { background: '#85A278' } }}
    >
      {visibleTabs.map((tab) => {
        index++;
        return (
          <Tab
            label={tab.label}
            value={tab.value[0]}
            className={tab.value.includes(selectedView) ? s.activeTab : undefined}
            key={tab.value[0]}
            disabled={index > maxLevel}
            style={{
              borderTop: '1px solid #00000030',
              borderRight: '1px solid #00000030',
              borderLeft: '1px solid #00000030',
              marginTop: (index * 5 - 5).toString() + 'px',
              boxShadow: '0px 2px 5px #00000030',
            }}
          />
        );
      })}
    </Tabs>
  );
};
