import { ArchiveFileLoader } from 'pages/ArchiveFile/ArchiveFileLoader';
import { ArchiveFileDetails } from 'pages/ArchiveFile/components/ArchiveFileDetails/ArchiveFileDetails';
import { FC, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ArchiveFilesProvider } from 'template/ArchiveFiles/ArchiveFilesContext';
import { ArchiveFilesFilteredView } from 'template/ArchiveFiles/ArchiveFilesFilteredView';
import { useComponentContext as useTreeTableComponentContext } from 'template/TreeTable/TreeTableContext';
import { useComponentContext } from '../TableSection/TableSectionContext';
import { Provider as DocumentsContextProvider } from './components/DocumentsView/DocumentsContext';
import { DocumentsView } from './components/DocumentsView/DocumentsView';
import { DocumentViewTabs } from './components/DocumentsView/components/DocumentViewTabs/DocumentViewTabs';
import { FolderTypeTabs } from './components/FolderTypeTabs/FolderTypeTabs';
import { LegalFolderProjectFoldersList } from './components/LegalFolderProjectFoldersList/LegalFolderProjectFoldersList';
import { LegalFolderView } from './components/LegalFolderView/LegalFolderView';
import { NewLegalFolderView } from './components/LegalFolderView/NewLegalFolderView';
import { NotAvailable } from './components/NotAvailable/NotAvailable';
import { ProjectFolderView } from './components/ProjectFolderView/ProjectFolderView';
import { Provider as ProjectFoldersContextProvider } from './components/ProjectFoldersView/ProjectFoldersContext';
import { ProjectFoldersView } from './components/ProjectFoldersView/ProjectFoldersView';
import { ProjectFoldersViewTabs } from './components/ProjectFoldersView/components/ProjectFoldersViewTabs/ProjectFoldersViewTabs';
import s from './style.module.scss';
import { legalFolderArchiveFilesTableHead } from 'constants/leagalFolderArchiveFiles';
import { useLegalFolderName } from 'hooks/legalFolderNameHook';
import paths from 'constants/paths';

interface ITableSectionUrlParams {
  tab: string;
}

export const TableSection: FC = () => {
  const { tab } = useParams<ITableSectionUrlParams>();

  const {
    activeTab,
    setActiveTab,
    legalFolderId,
    projectFolderId,
    projectDocumentId,
    newProjectDocumentTs,
    setDocumentViewTab,
    setProjectFoldersViewTab,
    activeDocumentTab,
    activeProjectFoldersTab,
  } = useComponentContext();

  const { initLegalFolderTab } = useTreeTableComponentContext();

  const { legalFolder, loading, load } = useLegalFolderName({ id: legalFolderId });
  useEffect(() => {
    load();
  }, [load]);

  const initPageLoadParams = useMemo(
    () =>
      legalFolder && !loading
        ? {
            filter: { partyIds: legalFolder?.parties.map(({ id }) => id) },
          }
        : undefined,
    [legalFolder, loading]
  );

  const contentPane = useMemo(() => {
    if (tab === 'pre-lms-table') {
      if (!initPageLoadParams) {
        return <></>;
      }
      return (
        <div style={{ marginTop: '7px', marginLeft: '30px' }}>
          <ArchiveFilesProvider
            tableStorageKey={`legalFolder-${legalFolderId}-ArchiveTable`}
            initPageLoadParams={initPageLoadParams}
          >
            <ArchiveFilesFilteredView
              tableHead={legalFolderArchiveFilesTableHead}
              viewPath={paths.treeViewClient.TREEVIEW_LEGAL_FOLDER_ARCHIVE.replace(
                ':id',
                legalFolder?.id ?? ''
              ).replace(':tab', 'pre-lms')}
            />
          </ArchiveFilesProvider>
        </div>
      );
    }

    if (tab === 'pre-lms') {
      return (
        <div style={{ marginTop: '7px', marginLeft: '30px' }}>
          <ArchiveFileLoader viewOnly={true}>
            <ArchiveFileDetails />
          </ArchiveFileLoader>
        </div>
      );
    }

    if (!legalFolderId && activeTab === 'LF') {
      return <NewLegalFolderView key="newLegalFolder"></NewLegalFolderView>;
    }

    if (legalFolderId && activeTab === 'PF') {
      return (
        <ProjectFoldersContextProvider
          legalFolderId={legalFolderId}
          projectFolderId={projectFolderId}
          newProjectFolderTs={newProjectDocumentTs}
          setProjectFoldersView={setProjectFoldersViewTab}
          activeProjectFoldersViewTab={activeProjectFoldersTab}
        >
          {projectFolderId ? <ProjectFoldersViewTabs></ProjectFoldersViewTabs> : undefined}
          <ProjectFoldersView></ProjectFoldersView>
        </ProjectFoldersContextProvider>
      );
    }

    return !legalFolderId || (!projectFolderId && activeTab !== 'LF') ? (
      <NotAvailable></NotAvailable>
    ) : activeTab === 'D' ? (
      <DocumentsContextProvider
        legalFolderId={legalFolderId}
        projectFolderId={projectFolderId}
        projectDocumentId={projectDocumentId}
        newProjectDocumentTs={newProjectDocumentTs}
        setDocumentView={setDocumentViewTab}
        activeDocumentViewTab={activeDocumentTab}
      >
        {projectDocumentId ? <DocumentViewTabs></DocumentViewTabs> : undefined}
        <DocumentsView key={projectFolderId}></DocumentsView>
      </DocumentsContextProvider>
    ) : activeTab === 'PF' ? (
      <ProjectFolderView key={projectFolderId}></ProjectFolderView>
    ) : !initLegalFolderTab ? (
      <LegalFolderView key={legalFolderId}></LegalFolderView>
    ) : (
      <LegalFolderProjectFoldersList key={legalFolderId}></LegalFolderProjectFoldersList>
    );
  }, [
    tab,
    activeTab,
    legalFolderId,
    projectFolderId,
    projectDocumentId,
    newProjectDocumentTs,
    setDocumentViewTab,
    setProjectFoldersViewTab,
    activeDocumentTab,
    activeProjectFoldersTab,
    initLegalFolderTab,
    initPageLoadParams,
    legalFolder,
  ]);

  return (
    <div>
      <FolderTypeTabs
        maxLevel={!legalFolderId ? 1 : !projectFolderId ? 2 : 3}
        selectedView={activeTab}
        setSelectedView={setActiveTab}
      ></FolderTypeTabs>
      <div className={s.contentPane}>{contentPane}</div>
    </div>
  );
};
