import { useMemo } from 'react';
import { DocusignsProps } from '../../../../Docusigns/interfaces';
import { IGanttChartTask } from 'components/GanttChart/interface';
import { signersByOrder } from 'utils/docusign';
import { createRoot } from 'react-dom/client';
import { flushSync } from 'react-dom';
import { capitalizeFirstLetter } from 'utils/formats';
import { formatTime } from 'utils/time';
import { UserInitials } from 'components';

interface IGantTask extends IGanttChartTask {}

export const useParseDocusignEvents = (docusignProps: DocusignsProps) => {
  const parsedEvents = useMemo(() => {
    // Process docusign events
    const docusignTasks: IGantTask[] = [];
    for (const docusign of docusignProps.docusigns) {
      const signersByRoutingOrder = signersByOrder(docusign);
      const version = docusignProps.versions.find((v) => v.id === docusign.documentVersionId);
      const endDate = docusign.envelope?.lastModifiedDateTime;

      const div = document.createElement('div');
      const root = createRoot(div);
      flushSync(() => {
        root.render(
          <div style={{ padding: '1rem 0' }}>
            <span
              style={{ color: 'black' }}
              className="event-comment-log-header event-comment-log-header-title"
            >
              DocuSign {capitalizeFirstLetter(docusign?.envelope?.status || 'unknown')}
            </span>
            {docusign?.envelope?.statusChangedDateTime ? (
              <>
                <br />
                Status Changed: {formatTime(docusign?.envelope?.statusChangedDateTime)}
              </>
            ) : undefined}
            {docusign?.envelope?.createdDateTime ? (
              <>
                <br />
                Created: {formatTime(docusign?.envelope?.createdDateTime)}
              </>
            ) : undefined}
            {docusign?.envelope?.lastModifiedDateTime ? (
              <>
                <br />
                Last Modified: {formatTime(docusign?.envelope?.lastModifiedDateTime)}
              </>
            ) : undefined}
            {docusign?.envelope?.completedDateTime ? (
              <>
                <br />
                Completed: {formatTime(docusign?.envelope?.completedDateTime)}
              </>
            ) : undefined}
            {docusign?.envelope?.expireEnabled &&
            docusign?.envelope?.expireDateTime &&
            !docusign?.envelope?.completedDateTime &&
            !['voided', 'deleted', 'declined'].includes(docusign?.envelope?.status ?? '') ? (
              <>
                <br />
                Expire Date: {formatTime(docusign?.envelope?.expireDateTime)}
              </>
            ) : undefined}
            <br />
            <br />
            File Name: {version?.blobName}
            <br />
            Original File Name: {version?.originalFilename}
            <br />
            Version v1.{version?.version}
            <br />
            {signersByRoutingOrder.map(({ signers }: { signers: any[] }) => {
              return signers?.map((signer: any) => {
                return (
                  <div style={{ display: 'inline-block', transform: 'scale(0.7)' }}>
                    <UserInitials
                      {...signer}
                      divStyle={
                        signer.status === 'sent'
                          ? { backgroundColor: 'yellow', color: 'black' }
                          : signer.status === 'completed'
                          ? { backgroundColor: 'green' }
                          : { backgroundColor: 'black', color: 'white' }
                      }
                    />
                  </div>
                );
              });
            })}
          </div>
        );
      });

      let customClass = '';
      switch (docusign.envelope?.status) {
        case 'voided':
          customClass = 'gantt_red';
          break;

        case 'completed':
          customClass = 'gantt_green';
          break;

        case 'sent':
          customClass = 'gantt_orange';
          break;

        default:
          break;
      }

      const ganttEvent: any = {
        id: docusign.envelopeId,
        name: `DocuSign ${capitalizeFirstLetter(docusign.envelope?.status)} v1.${
          version?.version
        } (${formatTime(endDate)})`,
        start: docusign.envelope?.createdDateTime,
        end: endDate,
        progress: 0,
        dependencies: '',
        type: 'docusign',
        ev: {
          htmlContentDivs: [div],
        },
        custom_class: customClass,
      };

      docusignTasks.push(ganttEvent);
    }
    return docusignTasks;
  }, [docusignProps]);

  return {
    parsedEvents,
  };
};
