import { Box, Button, Container, Grid, Paper, useMediaQuery, useTheme } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

// API
import { ApolloError, useLazyQuery, useQuery } from '@apollo/client';
import { getProjectFolders } from 'graphql/legalFolders/types/getProjectFolders';
import { getProjectFoldersCount } from 'graphql/legalFolders/types/getProjectFoldersCount';

// Icons
import { ClearAllOutlined as ClearAllOutlinedIcon } from '@mui/icons-material';

// Custom Components
import { paths } from 'constants/index';

// Utilities
import LoadingOverlay from 'react-loading-overlay-ts';
import { useHistory } from 'react-router-dom';

// Specific styles
import { DEFAULT_ROWS_PER_PAGE } from 'constants/config';
import './index.scss';

import { GET_PROJECT_FOLDERS, GET_PROJECT_FOLDERS_COUNT } from 'graphql/legalFolders/legalFolders';
import { ContainerFilter } from 'graphql/legalFolders/types/graphql-types';
import { IPageLoadParams } from './interface';

// Prepared to filter by owner
// import { usersList, usersList_users } from 'graphql/legalFolders/types/usersList';
// import { GET_USERS } from 'graphql/legalFolders/users';

import { IOrderByColumn, Table } from 'components/ui/Table/Table';
import { demacProjectFoldersTableHead } from 'constants/projectFoldersTable';

import { useUI } from 'contexts/UiContext';
import { GET_CONTRACTS_DIVISIONS } from 'graphql/legalFolders/divisions';
import { contractsDivisionsList } from 'graphql/legalFolders/types/contractsDivisionsList';
import { apolloErrorHandler } from 'utils/apolloErrorHandler';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

export const LegalFoldersTemplate: FC = () => {
  const history = useHistory();
  const { addSnackbar } = useUI();

  const [projectFolders, setLegalFolders] = useState<any[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [resetFilter, setResetFilter] = useState(new Date());

  const [pageLoadParams, setPageLoadParams] = useState<IPageLoadParams>({
    page: 0,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    filter: {},
  });

  const [
    loadLegalFoldersCount,
    {
      data: dataLegalFoldersCount,
      loading: loadingLegalFoldersCount,
      refetch: refetchLegalFoldersCount,
      called: calledLegalFoldersCount,
      error: errorLegalFoldersCount,
    },
  ] = useLazyQuery<getProjectFoldersCount>(GET_PROJECT_FOLDERS_COUNT);

  const { data: divisionsData } = useQuery<contractsDivisionsList>(GET_CONTRACTS_DIVISIONS);

  const [loadLegalFolders, { called, data, loading, refetch, error }] =
    useLazyQuery<getProjectFolders>(GET_PROJECT_FOLDERS, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

  // Prepared to filter by owner
  // const { data: ownersData } = useQuery<usersList>(GET_USERS, {
  //   variables: {
  //     filter: {
  //       isActive: true,
  //     },
  //     take: 1000,
  //     sort: {
  //       column: 'NAME',
  //       order: 'ASC',
  //     },
  //   },
  // });

  useEffect(() => {
    if (error) {
      apolloErrorHandler(addSnackbar!)(error as ApolloError);
    }
  }, [loading, error, addSnackbar]);

  useEffect(() => {
    if (errorLegalFoldersCount) {
      apolloErrorHandler(addSnackbar!)(errorLegalFoldersCount);
    }
  }, [loadingLegalFoldersCount, errorLegalFoldersCount, addSnackbar]);

  useEffect(() => {
    if (!loadingLegalFoldersCount && dataLegalFoldersCount) {
      setTotalItems(dataLegalFoldersCount.contract_containerCount);
    }
  }, [loadingLegalFoldersCount, dataLegalFoldersCount]);

  useEffect(() => {
    if (!loading && data) {
      setLegalFolders(
        data.contract_containers?.map((projectFolder) => {
          const {
            id,
            name,
            endDate: expirationDate,
            updatedAt: lastModifiedDate,
            documentCount,
            owners,
            createdAt,
          } = projectFolder;
          return {
            id,
            name,
            documentCount,
            expirationDate,
            lastModifiedDate,
            owners,
            createdAt,
          };
        })
      );
    }
  }, [data, loading]);

  useEffect(() => {
    const { rowsPerPage, page, orderByColumns, filter } = pageLoadParams;

    const variables = {
      take: rowsPerPage,
      skip: page * rowsPerPage,
      sort: orderByColumns?.length
        ? orderByColumns.map(({ order, orderBy }) => ({ order, column: orderBy }))
        : undefined,
      filter,
    };

    if (called) {
      refetch!(variables);
    } else {
      loadLegalFolders({ variables });
    }
  }, [loadLegalFolders, refetch, called, pageLoadParams]);

  useEffect(() => {
    const { filter } = pageLoadParams;
    const variables = {
      filter,
    };
    if (calledLegalFoldersCount) {
      refetchLegalFoldersCount!(variables);
    } else {
      loadLegalFoldersCount({ variables });
    }
  }, [loadLegalFoldersCount, calledLegalFoldersCount, refetchLegalFoldersCount, pageLoadParams]);

  const loadPage = useCallback(
    (orderByColumns: IOrderByColumn[] | undefined, page: number, rowsPerPage: number) => {
      setPageLoadParams((oldPageLoadParams) => ({
        ...oldPageLoadParams,
        orderByColumns,
        page,
        rowsPerPage,
      }));
    },
    []
  );

  const onLegalFolderSelect = useCallback(
    (id: string) => {
      const newUrl = paths.client.PROJECT_FOLDER_ID.replace(':id', id);
      history.push(newUrl);
      return true;
    },
    [history]
  );

  const onFilterChange = useCallback(
    (filterValues: any) => {
      if (pageLoadParams) {
        const newFilter: ContainerFilter = {
          nameContains: filterValues.name || undefined,
          createdAt: filterValues.createdAt || undefined,
          updatedAt: filterValues.lastModifiedDate || undefined,
          endDate: filterValues.expirationDate || undefined,
        };

        if (JSON.stringify(pageLoadParams.filter) !== JSON.stringify(newFilter)) {
          setPageLoadParams((oldPageLoadParams) => ({
            ...oldPageLoadParams,
            page: 0,
            filter: newFilter,
          }));
          return true;
        }
      }
      return false;
    },
    [pageLoadParams]
  );

  const filterApplied = useMemo(() => {
    const { filter } = pageLoadParams;
    return JSON.stringify(filter) !== '{}';
  }, [pageLoadParams]);

  const filterOptions = useMemo(() => {
    const getDivisionsPairs = () => {
      return (
        divisionsData?.divisions.map(({ id, name }) => {
          return {
            id,
            name,
          };
        }) || []
      );
    };

    // Prepared to filter by Owner
    // const getOwnersPairs = () => {
    //   return (
    //     ownersData?.users.map(({ id, name }) => {
    //       return {
    //         id,
    //         name,
    //       };
    //     }) || []
    //   );
    // };

    return {
      divisions: [{ id: 'All', name: 'All' }, ...getDivisionsPairs()],
      // Prepared to filter by Owner
      // ownerName: [{ id: 'All', name: 'All' }, ...getOwnersPairs()],
    };
  }, [
    divisionsData,

    // Prepared to filter by Owner
    // ,ownersData
  ]);

  const filterValues = useMemo(
    () => ({
      divisions: { id: 'All', name: 'All' },
      resetFilter,
    }),
    [resetFilter]
  );

  const clearAllFilters = () => {
    setResetFilter(new Date());
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container
      maxWidth="xl"
      style={
        matches
          ? { paddingLeft: '40px', paddingRight: '40px', paddingTop: '24px', minWidth: '100%' }
          : undefined
      }
    >
      <Paper
        elevation={2}
        style={
          matches
            ? { padding: '24px 1.5rem 16px 1.5rem', margin: '6px' }
            : { padding: '5px .5rem 5px .5rem', margin: '6px' }
        }
        id="main-paper"
      >
        <Box marginBottom={2} marginTop={1} style={{ paddingLeft: '0.5rem', paddingRight: '1rem' }}>
          <Grid container spacing={0} alignContent="center" alignItems="center">
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={debounce((): void => {
                  history.push(paths.client.PROJECT_FOLDER);
                }, DEBOUNCE_TIMEOUT)}
              >
                NEW PROJECT FOLDER
              </Button>
            </Grid>

            <Grid item xs={4}>
              <Box display="flex" justifyContent="center">
                {filterApplied ? (
                  <Button
                    onClick={debounce(clearAllFilters, DEBOUNCE_TIMEOUT)}
                    variant="outlined"
                    size="small"
                    endIcon={<ClearAllOutlinedIcon />}
                  >
                    Clear Filters
                  </Button>
                ) : undefined}
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end"></Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          style={{
            marginTop: '15px',
            borderRadius: '1px',
            boxShadow: '0px 2px 5px #00000030',
            border: '1px solid #00000030',
          }}
        >
          <LoadingOverlay spinner active={loading} text="Loading your content...">
            <Table
              totalItems={totalItems}
              dataCells={projectFolders}
              headCells={demacProjectFoldersTableHead}
              loadPage={loadPage}
              handleSelect={onLegalFolderSelect}
              filterOptions={filterOptions}
              filterValues={filterValues}
              onFilterChange={onFilterChange}
              initRowsPerPage={pageLoadParams.rowsPerPage}
            ></Table>
          </LoadingOverlay>
          <Box marginBottom={1} />
        </Box>
      </Paper>
    </Container>
  );
};
