import { Table } from 'components/ui/Table/Table';
import { demacPartyProjectsTableHead } from 'constants/partyProjectsTable';
import { GET_PARTY_PROJECTS, GET_PROJECT_COUNT } from 'graphql/legalFolders/projects';
import { useTableDataSource } from 'hooks/tableDataSourceHook';
import { FC, useCallback, useEffect, useMemo } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';

import { ClearAllOutlined as ClearAllOutlinedIcon } from '@mui/icons-material';
import { Box, Button, Grid } from '@mui/material';
import { DEBOUNCE_TIMEOUT, TABLE_PAGINATION } from 'constants/config';
import { ProjectFilter } from 'graphql/legalFolders/types/graphql-types';
import { debounce, pick } from 'lodash';

export interface IProjectsProps {
  partyId: string;
  setLoading?: ({ loading, data, error }: { loading: boolean; data: any; error: boolean }) => void;
}

const defaultFilterValues = {
  name: '',
};

const getCount = (data: any) => data.projectCount;
const getItems = (data: any) => data.projects;

export const Projects: FC<IProjectsProps> = ({ partyId, setLoading }) => {
  const filterToAPI = useCallback(
    (filterValues: any): ProjectFilter => ({
      partyIds: [partyId],
      keyOrNameContains: filterValues.name,
      isActive: true,
    }),
    [partyId]
  );

  const overridePageLoadParams = useMemo(() => {
    return {
      filter: { partyIds: [partyId] },
    };
  }, [partyId]);

  const { items, loadPage, totalItems, loading, onFilterChange, pageLoadParams, error } =
    useTableDataSource<any, any, any, ProjectFilter>({
      overridePageLoadParams,
      GET_COUNT_QUERY: GET_PROJECT_COUNT,
      GET_QUERY: GET_PARTY_PROJECTS,
      getCount,
      getItems,
      tableStorageKey: 'PartyProjectsTable',
      initRowsPerPage: 5,
      filterToAPI,
    });

  const filterOptions = useMemo(() => {
    return {};
  }, []);

  const clearAllFilters = () => {
    onFilterChange(defaultFilterValues);
  };

  const filterApplied = useMemo(() => {
    const { filterValues } = pageLoadParams;
    return (
      JSON.stringify(
        pick(
          filterValues,
          demacPartyProjectsTableHead.filter((item) => !!item.filter).map((item) => item.id)
        )
      ) !== JSON.stringify(defaultFilterValues)
    );
  }, [pageLoadParams]);

  useEffect(() => {
    setLoading && setLoading({ loading, data: items, error: !!error });
  }, [loading, items, error, setLoading]);

  return (
    <LoadingOverlay spinner active={loading} text="Loading your content...">
      {filterApplied || loading || totalItems > 0 ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                {filterApplied ? (
                  <Button
                    onClick={debounce(clearAllFilters, DEBOUNCE_TIMEOUT)}
                    variant="outlined"
                    size="small"
                    endIcon={<ClearAllOutlinedIcon />}
                  >
                    Clear Filters
                  </Button>
                ) : undefined}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box
              style={{
                borderRadius: '1px',
                boxShadow: '0px 2px 5px #00000030',
                border: '1px solid #00000030',
              }}
            >
              <Table
                totalItems={totalItems}
                dataCells={items}
                headCells={demacPartyProjectsTableHead}
                loadPage={loadPage}
                handleSelect={() => false}
                filterOptions={filterOptions}
                filterValues={pageLoadParams.filterValues || defaultFilterValues}
                onFilterChange={onFilterChange}
                initRowsPerPage={pageLoadParams.rowsPerPage}
                paginationProps={{
                  rowsPerPageOptions: TABLE_PAGINATION.includes(5)
                    ? TABLE_PAGINATION
                    : [5, ...TABLE_PAGINATION],
                }}
                initOrderByColumns={pageLoadParams.orderByColumns}
                initPage={pageLoadParams.page}
              ></Table>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <div style={{ paddingLeft: '20px' }}>Party projects list is empty</div>
      )}
    </LoadingOverlay>
  );
};
