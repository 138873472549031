import React, { FC, useCallback } from 'react';
import { ILegalFolderItem, IProjectFolderItem } from '../interface';
import { useProjectFolders } from './hooks';
import s from './style.module.scss';
import { useComponentContext } from 'template/TreeTable/TreeTableContext';
import LoadingOverlay from 'react-loading-overlay-ts';
import { FileDarkIcon, FileLightIcon } from 'images/icons';
import cn from 'classnames';
import { markSearchString } from 'utils/reformat';
import { IconButton, Tooltip } from '@mui/material';
import {
  FolderOpen as FolderOpenIcon,
  NotificationImportant as NotificationImportantIcon,
} from '@mui/icons-material';
import { blue, orange } from '@mui/material/colors';

import { useHistory } from 'react-router-dom';
import { paths } from 'constants/index';
import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';
import { useApolloUtil } from 'hooks/apolloUtilHook';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import LoadMore from 'components/LoadMore/LoadMore';
export interface IProjectFoldersProps {
  node: ILegalFolderItem;
  search: string;
  parentLoading?: boolean;
  myTask: boolean;
}

export const ProjectFolders: FC<IProjectFoldersProps> = ({
  node,
  search,
  parentLoading,
  myTask,
}) => {
  const { confirmRequest } = useFormChangedDialogContext();
  const history = useHistory();
  const { getSingleActionPendingDocumentId } = useApolloUtil();

  const { onSelectProjectFolder, onViewProjectFolder, selectedFolder } = useComponentContext();
  const { projects, loadMore, loading } = useProjectFolders({
    expanded: node.expanded,
    search,
    legalFolderId: node.id,
    myTask,
  });

  const onSelect = useCallback(
    (item: IProjectFolderItem) => () => {
      confirmRequest!((confirmed) => {
        confirmed && onSelectProjectFolder!(node.id, item.id);
      });
    },
    [node, onSelectProjectFolder, confirmRequest]
  );

  const onLegalFolderProjectFolderTableSelect = () => {
    confirmRequest!((confirmed) => {
      confirmed &&
        history.push(
          paths.treeViewClient.TREEVIEW_LEGAL_FOLDER_PROJECT_FOLDERS.replace(':id', node.id)
        );
    });
  };

  const onView = useCallback(
    (item: IProjectFolderItem) => () => {
      confirmRequest!((confirmed) => {
        confirmed && onViewProjectFolder!(node.id, item.id);
      });
    },
    [node, onViewProjectFolder, confirmRequest]
  );

  const bellProcess = useCallback(
    (id: string) => async () => {
      const { documentId, error, multipleFound } = await getSingleActionPendingDocumentId!({
        legalFolderIds: [node.id],
        containerIds: [id],
      });
      if (!error && !multipleFound && documentId) {
        confirmRequest!((confirmed) => {
          confirmed &&
            history.push(paths.treeViewClient.TREEVIEW_DOCUMENT_FM.replace(':id', documentId));
        });
      }
      if (multipleFound) {
        confirmRequest!((confirmed) => {
          confirmed &&
            history.push(
              paths.treeViewClient.TREEVIEW_PROJECT_FOLDER_TAB.replace(':id', id).replace(
                ':tab',
                'D'
              ) + '?order=action'
            );
        });
      }
    },
    [confirmRequest, getSingleActionPendingDocumentId, history, node.id]
  );

  return (
    <div className={s.projectFolders}>
      <div key="PF" style={{ display: 'flex', alignContent: 'center', marginBottom: '5px' }}>
        <div
          onClick={debounce(onLegalFolderProjectFolderTableSelect, DEBOUNCE_TIMEOUT)}
          style={{ cursor: 'pointer' }}
        >
          <IconButton size="small" style={{ color: blue[700] }}>
            <FolderOpenIcon fontSize="small"></FolderOpenIcon>
          </IconButton>
        </div>
        <div style={{ display: 'flex', marginTop: 'auto', marginBottom: 'auto' }}>
          Project Folders:
        </div>
      </div>
      <LoadingOverlay spinner active={loading && !parentLoading} text="Loading your content...">
        {projects &&
          projects?.items.map((item) => {
            return (
              <div className={s.projectFolderRow} key={item.id}>
                <div
                  onClick={debounce(onSelect(item), DEBOUNCE_TIMEOUT)}
                  style={{ cursor: 'pointer' }}
                  className={s.icon}
                >
                  {item.documentCount > 0 ? (
                    <FileDarkIcon className={s.documentIcon}></FileDarkIcon>
                  ) : (
                    <FileLightIcon className={s.documentIcon}></FileLightIcon>
                  )}
                </div>
                <div
                  onClick={debounce(onView(item), DEBOUNCE_TIMEOUT)}
                  style={{ cursor: 'pointer', wordBreak: 'break-all', wordWrap: 'break-word' }}
                  className={cn(
                    s.projectTitle,
                    {
                      [s.showActive]: selectedFolder?.projectFolderId === item.id,
                    },
                    s.separator
                  )}
                >
                  {markSearchString(item.name, search)}
                </div>
                {item.currentUserHasActionPending ? (
                  <div style={{ position: 'relative', width: '40px' }}>
                    <div style={{ position: 'absolute', top: '-3px', right: '4px' }}>
                      <Tooltip title="Review Requested">
                        <IconButton
                          size="small"
                          style={{ color: orange[400] }}
                          onClick={debounce(bellProcess(item.id), DEBOUNCE_TIMEOUT)}
                        >
                          <NotificationImportantIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                ) : undefined}
              </div>
            );
          })}
        {projects && projects?.totalItems > projects?.items.length ? (
          <div className={s.projectFolderRow}>
            <div className={s.projectTitle} style={{ paddingLeft: '0px' }}>
              <LoadMore loadMore={loadMore} autoLoadDisabled={true} />
            </div>
          </div>
        ) : undefined}
      </LoadingOverlay>
    </div>
  );
};
