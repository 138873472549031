import React, { FC, useMemo } from 'react';
import { DocumentsTemplate } from 'template/Documents/DocumentsTemplate';
import {
  IDocumentsTableHookLoadParams,
  SortOrder,
} from 'template/TreeTable/components/AllDocumentsTable/documentsTableHook';

export const DocumentsNotSignedByAllParties: FC = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const legalFolderId = params.get('lf');
  const projectFolderId = params.get('pf');

  const initPageLoadParams: Partial<IDocumentsTableHookLoadParams> | undefined =
    projectFolderId || legalFolderId
      ? {
          orderByColumns: [
            {
              order: SortOrder.DESC,
              orderBy: 'CURRENT_USER_ACTION_PENDING',
            },
          ],
        }
      : undefined;

  const overridePageLoadParams = useMemo(() => {
    return {
      filterValues: {
        onlyPartyApprovalPending: true,
      },
      filter: {
        onlyPartyApprovalPending: true,
      },
    };
  }, []);

  return (
    <DocumentsTemplate
      overridePageLoadParams={overridePageLoadParams}
      initPageLoadParams={initPageLoadParams}
      tableStorageKey={'reportDocumentsNotSignedByAllParties'}
      title="Documents not Signed by All Parties"
    ></DocumentsTemplate>
  );
};
