import { IHeadCell } from 'components/ui/Table/components/TableHead/TableHead';
import { SortOrder } from 'components/ui/Table/components/HeaderCell/HeaderCell';
import { ArchiveFileSortableColumn } from 'graphql/legalFolders/types/graphql-types';
import { convertToFileSize } from 'utils/formats';
import {
  TransformArchiveFileDocumentDate,
  TransformArchiveFilePartyName,
  TransformMultiline,
} from 'utils/TableTransformFunctions';

export const allArchiveFilesTableHead: IHeadCell[] = [
  {
    id: 'legalFolders',
    numeric: false,
    disablePadding: false,
    label: 'Legal Folder',
    inputLabel: 'Input legal folder name',
    sortable: true,
    sortBy: ArchiveFileSortableColumn.LEGAL_FOLDER_NAME,
    filter: 'text',
    padding: 'half',
    dataCell: {
      shrink: 'content',
      padding: 'half',
    },
    transformFunction: (data, row, order, sorted) => {
      if (!data?.length) return '--';
      if (!sorted) return data.map((item: { name: string }) => item.name).join(', ');
      if (order === SortOrder.ASC) {
        return data
          .map((item: { name: string }) => item.name)
          .sort()
          .join(', ');
      }
      return data
        .map((item: { name: string }) => item.name)
        .sort()
        .reverse()
        .join(', ');
    },
    transformDataCell: TransformMultiline,
  },
  {
    id: 'originalFilename',
    numeric: false,
    disablePadding: false,
    label: 'File Name',
    sortable: true,
    sortBy: ArchiveFileSortableColumn.FILE_NAME,
    filter: 'text',
    padding: 'half',
    dataCell: {
      shrink: 'content',
      padding: 'half',
    },
    inputLabel: 'Input file name',
    transformFunction: (data) => data || '--',
  },
  {
    id: 'party',
    numeric: false,
    disablePadding: false,
    label: 'Other Party',
    inputLabel: 'Input other party name',
    sortBy: ArchiveFileSortableColumn.PARTY_NAME,
    sortable: true,
    filter: 'text-dropdown',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: (data) =>
      data
        ? (data.projectSetupClientCode ? data.projectSetupClientCode + ' - ' : '') + data.name
        : '--',
    transformDataCell: TransformArchiveFilePartyName,
  },
  {
    id: 'originalFilePath',
    numeric: false,
    disablePadding: false,
    label: 'Path',
    sortable: true,
    sortBy: ArchiveFileSortableColumn.FILE_PATH,
    filter: 'text-dropdown',
    padding: 'half',
    dataCell: {
      //   shrink: 'content',
      padding: 'half',
      wrap: 'box',
    },
    inputLabel: 'Input file path or other party name',
    transformFunction: (data) => data || '/',
  },
  {
    id: 'originalFileExtension',
    numeric: false,
    disablePadding: false,
    label: 'Extension',
    sortable: true,
    sortBy: ArchiveFileSortableColumn.FILE_EXTENSION,
    filter: 'hierarchy-multiple',
    padding: 'half',
    dataCell: {
      shrink: 'content',
      padding: 'half',
    },
    transformFunction: (data) => data || '--',
  },
  // {
  //   id: 'mimeType',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Mime Type',
  //   sortable: true,
  //   sortBy: ArchiveFileSortableColumn.MIME_TYPE,
  //   filter: 'hierarchy-multiple',
  //   padding: 'half',
  //   dataCell: {
  //     shrink: 'content',
  //     padding: 'half',
  //   },
  //   transformFunction: (data) => data || '--',
  // },
  {
    id: 'blobSize',
    numeric: false,
    disablePadding: false,
    label: 'File Size',
    sortable: true,
    sortBy: ArchiveFileSortableColumn.BLOB_SIZE,
    filter: 'dropdown',

    dataCell: {
      connected: 'right',
      shrink: 'content',
    },

    transformFunction: (data) => convertToFileSize(data),
  },
  {
    id: 'lastModifiedDate',
    numeric: false,
    disablePadding: false,
    label: 'Changed',
    sortable: true,
    sortBy: ArchiveFileSortableColumn.UPDATED_AT,
    filter: 'date',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: (date) =>
      new Date(date).toLocaleDateString('en-EN', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }),
  },
  {
    id: 'documentDate',
    numeric: false,
    disablePadding: false,
    label: 'Document Date',
    sortable: true,
    sortBy: ArchiveFileSortableColumn.DOCUMENT_DATE,
    filter: 'date',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: (date) =>
      date
        ? new Date(date).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : '--',
    transformDataCell: TransformArchiveFileDocumentDate,
  },
  // {
  //   id: 'createdAt',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Created',
  //   sortable: true,
  //   sortBy: ArchiveFileSortableColumn.CREATED_AT,
  //   filter: 'date',
  //   padding: 'half',
  //   dataCell: {
  //     padding: 'half',
  //     shrink: 'content',
  //   },
  //   transformFunction: (date) =>
  //     new Date(date).toLocaleDateString('en-EN', {
  //       year: 'numeric',
  //       month: 'numeric',
  //       day: 'numeric',
  //     }),
  // },
];
