import React, { useState } from 'react';

import { CssBaseline } from '@mui/material';
import {
  ThemeProvider as MuiThemeProvider,
  createTheme as createMuiTheme,
} from '@mui/material/styles';

const createTheme = (): any => {
  return createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960, // mui 900
        lg: 1280, // mui 1200
        xl: 1920, // mui 1536
      },
    },
    spacing: [0, 8, 16, 24, 32, 40],
    palette: {
      action: {
        disabled: 'rgba(0, 0, 0, 0.28)',
      },
      common: {
        black: '#000',
        white: '#fff',
      },
      background: { paper: '#fff', default: '#BED0B6' },
      primary: {
        light: '#057df6',
        main: '#006ad4',
        dark: '#0054a8',
        contrastText: '#fff',
      },

      secondary: {
        light: '#f5dd78',
        main: '#BED0B6',
        dark: '#c8b35c',
        contrastText: '#000',
      },

      error: {
        light: '#e57373',
        main: '#f44336',
        dark: '#d32f2f',
        contrastText: '#fff',
      },

      text: {
        primary: 'rgba(0, 0, 0, 0.87)',
        secondary: 'rgba(0, 0, 0, 0.54)',
        disabled: 'rgba(0, 0, 0, 0.28)',
      },
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: 'rgba(0, 0, 0, 0.46)',
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            fontSize: '0.875rem',
            lineHeight: 1.43,
            letterSpacing: '0.01071em',
          },
          textarea: {
            fontSize: '0.875rem',
            lineHeight: 1.43,
            letterSpacing: '0.01071em',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            lineHeight: '1.1876em',
            backgroundColor: 'white',
          },
          input: {
            '&.MuiOutlinedInput-input': {
              height: '1.1876em',
              backgroundColor: 'white',
            },
          },
        },
      },
      MuiGrid2: {
        styleOverrides: {
          root: {},
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            '.MuiOutlinedInput-root': {
              padding: '6px',
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          marginDense: {
            marginTop: 0,
            marginBottom: 0,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 12,
            paddingBottom: 12,
          },
          sizeSmall: {
            padding: '3px',
            fontSize: '1.125rem',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            '@media (min-width: 0px)': {
              minWidth: 0,
            },
          },
          textColorPrimary: {
            color: 'inherit',
            opacity: '0.7',
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            '&.MuiTab-root': {
              lineHeight: '1.75',
              padding: '6px 12px',
            },
            '&.MuiChip-root': {
              backgroundColor: '#e0e0e0',
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          // root: {
          //   fontSize: '0.8rem !important',
          // },
          label: {
            fontSize: '0.8rem !important',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            '&.MuiAccordion-root.Mui-expanded:first-of-type': {
              margin: '16px 0',
            },
          },
        },
      },
    },
  });
};

const Theme = React.forwardRef(function Theme(props: any, ref: any) {
  const [theme] = useState(createTheme());
  const { component: Component = 'div', ...other } = props;

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Component ref={ref} {...other} />
    </MuiThemeProvider>
  );
});

export default Theme;
