import { useLazyQuery } from '@apollo/client';
import { GET_ARCHIVE_FILE } from 'graphql/legalFolders/archiveFiles';
import { archiveFile } from 'graphql/legalFolders/types/archiveFile';
import { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ArchiveFileProvider } from 'template/ArchiveFile/ArchiveFileContext';
import { IArchiveFileData } from 'template/ArchiveFile/components/ArchiveFile/interface';

interface IUrlParams {
  archiveFileId: string;
}

export interface IArchiveFileLoaderProps {
  children: any;
  viewOnly?: boolean;
}

export const ArchiveFileLoader: FC<IArchiveFileLoaderProps> = ({ children, viewOnly }) => {
  const { archiveFileId } = useParams<IUrlParams>();

  const [archiveFile, setArchiveFile] = useState<IArchiveFileData>();

  const [getArchiveFile, { called, data, loading, error, refetch }] = useLazyQuery<archiveFile>(
    GET_ARCHIVE_FILE,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  const loadArchiveFile = useCallback(async () => {
    const variables = { id: archiveFileId };

    if (called) {
      await refetch!(variables);
    } else {
      await getArchiveFile({ variables });
    }
  }, [refetch, called, getArchiveFile, archiveFileId]);

  useEffect(() => {
    loadArchiveFile();
  }, [loadArchiveFile]);

  useEffect(() => {
    if (data && !loading && !error) {
      const foundArchiveFile = data.contract_archiveFile;

      if (foundArchiveFile) {
        setArchiveFile({ ...foundArchiveFile });
      }
    }
  }, [data, loading, error, archiveFileId]);

  return (
    <ArchiveFileProvider loadedArchiveFile={archiveFile} refetch={refetch} viewOnly={viewOnly}>
      {children}
    </ArchiveFileProvider>
  );
};
