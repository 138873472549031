import { SaveAlt } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useArchiveFileContext } from 'template/ArchiveFile/ArchiveFileContext';

import { convertToFileSize } from 'utils/formats';

import { Edit as EditIcon, Clear as RemoveIcon } from '@mui/icons-material';
import { UpdateArchiveParty } from '../UpdateArchiveFileParty/UpdateArchiveFileParty';
import { RemoveArchiveParty } from '../RemoveArchiveFileParty/RemoveArchiveFileParty';
import { paths } from '../../../../constants';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { PickersActionBarAction } from '@mui/x-date-pickers/PickersActionBar/PickersActionBar';
import { useUI } from 'contexts/UiContext';

export const ArchiveFile: FC = () => {
  const { addSnackbar } = useUI();

  const { archiveFile, setArchiveFile, onSubmit, refetch, changed } = useArchiveFileContext();

  const [showEditParty, setShowEditParty] = useState(false);
  const [showUnsetParty, setShowUnsetParty] = useState(false);

  const handleSubmit = useCallback(async () => {
    if (!changed) {
      return;
    }
    const result: { isError: boolean; errors?: string[] } | undefined = await onSubmit();
    if (result) {
      const { isError, errors } = result;
      if (isError) {
        addSnackbar!({
          text: 'Error...' + errors?.join(' '),
          severity: 'error',
        });
      } else {
        addSnackbar!({
          text: 'Success',
          severity: 'success',
        });
        refetch();
      }
    }
  }, [onSubmit, refetch, addSnackbar, changed]);

  const {
    blobSize,
    mimeType,
    originalFileExtension,
    originalFilePath,
    originalFilename,
    documentDate,
    party,
    downloadUrl,
    canUpdate,
    legalFolders,
  } = archiveFile!;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const downloadLinkRef = useRef<HTMLAnchorElement | null>(null);

  const documentDateAsDate = useMemo(() => {
    if (!documentDate) {
      return;
    }
    const date = new Date(documentDate);
    return date;
  }, [documentDate]);

  const localDocumentDate = useMemo(() => {
    if (!documentDate) {
      return;
    }
    const date = new Date(documentDate);
    const localDate = date.toLocaleDateString();
    return localDate;
  }, [documentDate]);

  return (
    <div>
      <Box mt={4} />
      <Grid container padding={0} spacing={2} alignContent="center" alignItems="center">
        <Grid
          xs={12}
          sm={4}
          style={{
            display: 'flex',
            alignItems: 'top',
            minHeight: matches ? '44px' : undefined,
          }}
        >
          <Typography variant="body2" className="label-title">
            {`Legal folder${legalFolders?.length ? 's' : ''}:`}
          </Typography>
        </Grid>
        <Grid xs={12} sm={8}>
          {legalFolders?.length
            ? legalFolders
                .map(({ name }) => name)
                .sort()
                .map((name, index) => {
                  return <div style={index > 0 ? { marginTop: '16px' } : undefined}>{name}</div>;
                })
            : '--'}
        </Grid>
      </Grid>
      <Box m={2} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Divider />
            <Box m={2} />
          </Grid>
        </Grid>
      </Grid>
      <Box m={2} />
      <Grid container padding={0} spacing={2} alignContent="center" alignItems="center">
        <Grid
          xs={12}
          sm={4}
          style={{
            display: 'flex',
            alignItems: 'center',
            minHeight: matches ? '44px' : undefined,
          }}
        >
          <Typography variant="body2" className="label-title">
            Other Party Name:
          </Typography>
        </Grid>
        {!showEditParty && !showUnsetParty ? (
          <Grid xs={12} sm={8} style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <div>
              {party?.name ? (
                <a
                  href={paths.client.CLIENT_DETAILS.replace(':id', party.id) || undefined}
                  style={{ color: '#000000de' }}
                  rel="noreferrer"
                >
                  {party.name}
                </a>
              ) : (
                '--'
              )}
            </div>
            {canUpdate ? (
              <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                {party?.name ? (
                  <div>
                    <Tooltip title="View other party removal form">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setShowUnsetParty(true);
                        }}
                      >
                        <RemoveIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </div>
                ) : undefined}
                <div>
                  <Tooltip title="Edit other party assignment">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setShowEditParty(true);
                      }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            ) : undefined}
          </Grid>
        ) : undefined}
        {showEditParty ? (
          <Grid xs={12} sm={8}>
            <UpdateArchiveParty
              onClose={() => setShowEditParty(false)}
              originalFilePath={originalFilePath}
            />
          </Grid>
        ) : undefined}
        {showUnsetParty ? (
          <Grid xs={12} sm={8}>
            <RemoveArchiveParty
              onClose={() => setShowUnsetParty(false)}
              originalFilePath={originalFilePath}
            />
          </Grid>
        ) : undefined}
      </Grid>
      <Box m={2} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Divider />
            <Box m={2} />
          </Grid>
        </Grid>
      </Grid>
      <Box m={2} />
      <Grid container padding={0} spacing={2} alignContent="center" alignItems="center">
        <Grid
          xs={12}
          sm={4}
          style={{
            display: 'flex',
            alignItems: 'center',
            minHeight: matches ? '44px' : undefined,
          }}
        >
          <Typography variant="body2" className="label-title">
            File Name:
          </Typography>
        </Grid>
        <Grid xs={12} sm={8}>
          {downloadUrl ? (
            <a
              ref={downloadLinkRef}
              href={downloadUrl || undefined}
              style={{ color: '#000000de' }}
              target="_blank"
              rel="noreferrer"
            >
              {originalFilename}
            </a>
          ) : (
            originalFilename
          )}
        </Grid>
        <Grid
          xs={12}
          sm={4}
          style={{
            display: 'flex',
            alignItems: 'center',
            minHeight: matches ? '44px' : undefined,
          }}
        >
          <Typography variant="body2" className="label-title">
            Size:
          </Typography>
        </Grid>
        <Grid xs={12} sm={8}>
          {convertToFileSize(blobSize)}
        </Grid>
        <Grid
          xs={12}
          sm={4}
          style={{
            display: 'flex',
            alignItems: 'center',
            minHeight: matches ? '44px' : undefined,
          }}
        >
          <Typography variant="body2" className="label-title">
            Mime Type:
          </Typography>
        </Grid>
        <Grid xs={12} sm={8}>
          {mimeType}
        </Grid>
        <Grid
          xs={12}
          sm={4}
          style={{
            display: 'flex',
            alignItems: 'center',
            minHeight: matches ? '44px' : undefined,
          }}
        >
          <Typography variant="body2" className="label-title">
            Extension:
          </Typography>
        </Grid>
        <Grid xs={12} sm={8}>
          {originalFileExtension}
        </Grid>
        <Grid
          xs={12}
          sm={4}
          style={{
            display: 'flex',
            alignItems: 'center',
            minHeight: matches ? '44px' : undefined,
          }}
        >
          <Typography variant="body2" className="label-title">
            Folder:
          </Typography>
        </Grid>
        <Grid xs={12} sm={8}>
          {originalFilePath || '/'}
        </Grid>
        <Grid
          xs={12}
          sm={4}
          style={{
            display: 'flex',
            alignItems: 'center',
            minHeight: matches ? '44px' : undefined,
          }}
        >
          <Typography variant="body2" className="label-title">
            Document Date:
          </Typography>
        </Grid>
        {canUpdate ? (
          <>
            <Grid xs={12} sm={8}>
              <DatePicker
                value={documentDateAsDate || null}
                onChange={(date: Date | null) => {
                  setArchiveFile!((old: Date | null) => {
                    return {
                      ...old,
                      documentDate: date ? date.toISOString().split('T')[0] : null,
                    };
                  });
                }}
                componentsProps={{
                  actionBar: { actions: ['clear'] as PickersActionBarAction[] },
                }}
                renderInput={(props) => <TextField variant="outlined" size="small" {...props} />}
              ></DatePicker>
            </Grid>
            <Grid xs={12} sm={4}></Grid>
            <Grid xs={12} sm={8}>
              <div
                style={{
                  display: 'flex',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'left',
                  gap: '1em',
                  cursor: changed ? 'pointer' : undefined,
                  paddingTop: '1em',
                }}
                onClick={debounce(() => {
                  handleSubmit();
                }, DEBOUNCE_TIMEOUT)}
              >
                <Button variant="contained" color="primary" disabled={!changed}>
                  Submit
                </Button>
              </div>
            </Grid>
          </>
        ) : (
          <Grid xs={12} sm={8}>
            {localDocumentDate || '--'}
          </Grid>
        )}
      </Grid>
      {downloadUrl ? (
        <>
          <Box m={2} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Divider />
                <Box m={2} />
              </Grid>
            </Grid>
          </Grid>
          <Box m={3} />
          <Grid container spacing={2} alignContent="center" alignItems="center">
            <Grid
              xs={12}
              sm={4}
              style={{
                display: 'flex',
                alignItems: 'center',
                minHeight: matches ? '44px' : undefined,
              }}
            >
              <Typography variant="body2" className="label-title">
                File Download:
              </Typography>
            </Grid>
            <Grid xs={12} sm={8}>
              <div
                style={{
                  display: 'flex',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'left',
                  gap: '1em',
                  cursor: 'pointer',
                }}
                onClick={debounce(() => {
                  downloadLinkRef.current?.click();
                }, DEBOUNCE_TIMEOUT)}
              >
                <SaveAlt></SaveAlt>
                <Button variant="outlined" color="primary">
                  Download
                </Button>
              </div>
            </Grid>
          </Grid>
        </>
      ) : undefined}
    </div>
  );
};
