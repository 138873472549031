import { SortOrder } from 'components/ui/Table/components/HeaderCell/HeaderCell';
import { IHeadCell } from 'components/ui/Table/components/TableHead/TableHead';
import { DocumentSortableColumn } from 'graphql/legalFolders/types/graphql-types';
// import { SelectLegalFolder } from 'template/SelectLegalFolder/SelectLegalFolder';
// import { SelectProjectFolder } from 'template/SelectProjectFolder/SelectProjectFolder';
import { capitalizeFirstLetter } from 'utils/formats';
import {
  TransformCancelOrDeleteIcon,
  TransformCurrentlyReviewing,
  TransformDocumentStatus,
  TransformNameAddDocusignLogo,
  TransformOwners,
} from 'utils/TableTransformFunctions';

export const allDocumentsTableHead: IHeadCell[] = [
  {
    id: 'legalFolderName',
    numeric: false,
    disablePadding: false,
    label: 'Legal Folder',
    inputLabel: 'Input legal folder name',
    sortable: true,
    filter: 'text-dropdown',
    sortBy: DocumentSortableColumn.LEGAL_FOLDER_NAME,
    padding: 'half',
    dataCell: {
      shrink: 'content',
      padding: 'half',
      wrap: 'box',
    },
    transformFunction: (data, row: any | undefined) => row?.legalFolder?.name || '--',
  },

  // {
  //   id: 'legalFolder',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Legal Folder',
  //   sortable: true,
  //   sortBy: DocumentSortableColumn.LEGAL_FOLDER_NAME,
  //   padding: 'half',
  //   dataCell: {
  //     shrink: 'content',
  //     padding: 'half',
  //     wrap: 'box',
  //   },
  //   transformFunction: (data: any | undefined) => data?.name,
  //   filter: 'component',
  //   filterComponent: SelectLegalFolder,
  // },
  {
    id: 'projectFolderName',
    numeric: false,
    disablePadding: false,
    label: 'Project Folder',
    inputLabel: 'Input project folder name',
    filter: 'text-dropdown',
    sortable: true,
    sortBy: DocumentSortableColumn.CONTAINER_NAME,
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: (data, row: any | undefined) => row?.projectFolder?.name || '--',
  },
  // {
  //   id: 'projectFolder',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Project Folder',
  //   sortable: true,
  //   sortBy: DocumentSortableColumn.CONTAINER_NAME,
  //   padding: 'half',
  //   dataCell: {
  //     padding: 'half',
  //     shrink: 'content',
  //   },
  //   transformFunction: (data) => data?.name,
  //   filter: 'component',
  //   filterComponent: SelectProjectFolder,
  // },

  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Document',
    inputLabel: 'Input document name',
    sortable: true,
    sortBy: DocumentSortableColumn.NAME,
    filter: 'text-dropdown',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformDataCell: TransformNameAddDocusignLogo,
  },
  {
    id: 'divisions',
    numeric: false,
    disablePadding: false,
    label: 'Divisions',
    inputLabel: 'Select divisions',
    sortable: true,
    sortBy: DocumentSortableColumn.DIVISION_NAME,
    filter: 'hierarchy-multiple',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
      align: 'left',
      wrap: 'box',
    },
    transformFunction: (data, row, order, sorted) => {
      if (!data?.length) return '-';
      if (!sorted) return data.map((item: { name: string }) => item.name).join(', ');
      if (order === SortOrder.ASC) {
        return data
          .map((item: { name: string }) => item.name)
          .sort()
          .join(', ');
      }
      return data
        .map((item: { name: string }) => item.name)
        .sort()
        .reverse()
        .join(', ');
    },
  },
  {
    id: 'documentType',
    numeric: false,
    disablePadding: false,
    label: 'Type',
    inputLabel: 'Select document types',
    sortable: true,
    sortBy: DocumentSortableColumn.DOCUMENT_TYPE_NAME,
    filter: 'hierarchy-multiple',
    padding: 'half',
    dataCell: {
      wrap: 'box',
      padding: 'half',
      shrink: 'content',
    },
  },
  {
    id: 'currentlyReviewing',
    numeric: false,
    disablePadding: false,
    label: 'Reviewing',
    inputLabel: 'Input reviewing user name',
    sortable: true,
    sortBy: DocumentSortableColumn.PENDING_REVIEWER_NAMES,
    filter: 'text-dropdown',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformDataCell: TransformCurrentlyReviewing,
  },
  {
    id: 'statusFolder',
    numeric: false,
    disablePadding: false,
    label: 'Status Folder',
    sortable: true,
    sortBy: DocumentSortableColumn.STATUS_FOLDER,
    filter: 'hierarchy-multiple',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: (data: string) => capitalizeFirstLetter(data.toLowerCase()),
  },
  {
    id: 'lastModifiedDate',
    numeric: false,
    disablePadding: false,
    label: 'Changed',
    sortable: true,
    sortBy: DocumentSortableColumn.UPDATED_AT,
    filter: 'date',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: (date) =>
      new Date(date).toLocaleDateString('en-EN', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }),
  },
  {
    id: 'ownerNames',
    numeric: false,
    disablePadding: false,
    label: 'Owners',
    inputLabel: 'Input owner name',
    sortable: true,
    sortBy: DocumentSortableColumn.OWNER_NAMES,
    filter: 'text-dropdown',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformDataCell: TransformOwners,
  },

  // {
  //   id: 'lastActionBy',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Last Action By',
  //   sortable: false,
  //   filter: 'text',
  //   dataCell: {
  //     shrink: 'content',
  //   },
  // },

  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    sortable: true,
    sortBy: DocumentSortableColumn.STATUS,
    filter: 'hierarchy-multiple',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformDataCell: TransformDocumentStatus,
  },
  {
    id: 'canDelete',
    numeric: false,
    disablePadding: false,
    label: '',
    sortable: false,
    // filter: 'text',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformDataCell: TransformCancelOrDeleteIcon,
  },
];
