import { IHeadCell } from 'components/ui/Table/components/TableHead/TableHead';
import { ArchiveFileSortableColumn } from 'graphql/legalFolders/types/graphql-types';
import { convertToFileSize } from 'utils/formats';
import {
  TransformArchiveFileDocumentDate,
  //   TransformArchiveFilePartyName,
} from 'utils/TableTransformFunctions';

export const legalFolderArchiveFilesTableHead: IHeadCell[] = [
  {
    id: 'originalFilename',
    numeric: false,
    disablePadding: false,
    label: 'File Name',
    sortable: true,
    sortBy: ArchiveFileSortableColumn.FILE_NAME,
    filter: 'text',
    padding: 'half',
    dataCell: {
      shrink: 'content',
      padding: 'half',
    },
    inputLabel: 'Input file name',
    transformFunction: (data) => data || '--',
  },
  {
    id: 'originalFilePath',
    numeric: false,
    disablePadding: false,
    label: 'Path',
    sortable: true,
    sortBy: ArchiveFileSortableColumn.FILE_PATH,
    filter: 'text-dropdown',
    padding: 'half',
    dataCell: {
      //   shrink: 'content',
      padding: 'half',
      wrap: 'box',
    },
    inputLabel: 'Input file path or other party name',
    transformFunction: (data) => data || '/',
  },

  {
    id: 'blobSize',
    numeric: false,
    disablePadding: false,
    label: 'File Size',
    sortable: true,
    sortBy: ArchiveFileSortableColumn.BLOB_SIZE,
    filter: 'dropdown',

    dataCell: {
      connected: 'right',
      shrink: 'content',
    },

    transformFunction: (data) => convertToFileSize(data),
  },

  {
    id: 'documentDate',
    numeric: false,
    disablePadding: false,
    label: 'Document Date',
    sortable: true,
    sortBy: ArchiveFileSortableColumn.DOCUMENT_DATE,
    filter: 'date',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: (date) =>
      date
        ? new Date(date).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : '--',
    transformDataCell: TransformArchiveFileDocumentDate,
  },
];
