import { ApolloError, useLazyQuery } from '@apollo/client';
import { useTableStorage } from 'components/ui/Table/storage/tableStorageHook';
import { IOrderByColumn } from 'components/ui/Table/Table';
import { DEFAULT_ROWS_PER_PAGE } from 'constants/config';
import { GET_PROJECT_FOLDERS, GET_PROJECT_FOLDERS_COUNT } from 'graphql/legalFolders/legalFolders';
import { getProjectFolders } from 'graphql/legalFolders/types/getProjectFolders';
import { getProjectFoldersCount } from 'graphql/legalFolders/types/getProjectFoldersCount';

import { useCallback, useEffect, useMemo, useState } from 'react';

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface IProjectFoldersTableHookLoadParams {
  orderByColumns: IOrderByColumn[] | undefined;
  page: number;
  rowsPerPage: number;
  filter?: any;
  filterValues?: any;
}

export interface IProjectFoldersTableHookProps {
  initFilter?: any;
  overridePageLoadParams?: any;
  initPageLoadParams?: Partial<IProjectFoldersTableHookLoadParams>;
  myTask: boolean;
  tableStorageKey?: string;
}

export interface IProjectFoldersTableHookValue {
  totalItems: number;
  projectFolders: any;
  loadPage: (
    orderByColumns: IOrderByColumn[] | undefined,
    page: number,
    rowsPerPage: number
  ) => void;
  loading: boolean;
  error: ApolloError | undefined;
  onFilterChange: (filterValues: any) => boolean;
  filterApplied: any;
  pageLoadParams: any;
}

const defaultFilterValues = {};

export const useProjectFoldersTableHook = ({
  initFilter,
  overridePageLoadParams,
  myTask,
  initPageLoadParams,
  tableStorageKey,
}: IProjectFoldersTableHookProps): IProjectFoldersTableHookValue => {
  const { setItem, getItem } = useTableStorage({
    key: tableStorageKey || 'AllProjectFoldersTable',
  });

  const [totalItems, setTotalItems] = useState(0);
  const [projectFolders, setProjectFolders] = useState<any[]>([]);
  const [pageLoadParams, setPageLoadParams] = useState<IProjectFoldersTableHookLoadParams>({
    page: 0,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    filter: initFilter,

    ...initPageLoadParams,
    ...getItem(),
  });

  useEffect(() => {
    if (overridePageLoadParams) {
      const { filter } = overridePageLoadParams;

      setPageLoadParams((current) => ({
        ...current,
        ...overridePageLoadParams,
        filter: { ...current.filter, ...filter },
        filterValues: {
          ...defaultFilterValues,
          ...current.filterValues,
          ...overridePageLoadParams.filterValues,
        },
      }));
    }
  }, [overridePageLoadParams]);

  useEffect(() => {
    setItem(pageLoadParams);
  }, [pageLoadParams, setItem]);

  const loadPage = useCallback(
    (orderByColumns: IOrderByColumn[] | undefined, page: number, rowsPerPage: number) => {
      setPageLoadParams((oldPageLoadParams) => ({
        ...oldPageLoadParams,
        orderByColumns,
        page,
        rowsPerPage,
      }));
    },
    []
  );

  const [loadProjectFolders, { called, data, loading, error, refetch }] =
    useLazyQuery<getProjectFolders>(GET_PROJECT_FOLDERS, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

  const [
    loadProjectFoldersCount,
    {
      called: calledCount,
      data: dataCount,
      loading: loadingCount,
      error: errorCounter,
      refetch: refetchCount,
    },
  ] = useLazyQuery<getProjectFoldersCount>(GET_PROJECT_FOLDERS_COUNT, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    const { rowsPerPage, page, orderByColumns, filter } = pageLoadParams;

    const variables = {
      take: rowsPerPage,
      skip: page * rowsPerPage,
      sort: orderByColumns?.length
        ? orderByColumns.map(({ order, orderBy }) => ({ order, column: orderBy }))
        : undefined,
      filter: { ...filter, onlyCurrentUserDocuments: myTask },
    };

    if (called) {
      refetch!(variables);
    } else {
      loadProjectFolders({ variables });
    }
  }, [loadProjectFolders, refetch, called, pageLoadParams, myTask]);

  useEffect(() => {
    if (!loading && data) {
      setProjectFolders(
        data.contract_containers?.map((projectFolder) => {
          const {
            id,
            name,
            endDate: expirationDate,
            updatedAt: lastModifiedDate,
            documentCount,
            owners,
            createdAt,
            documentsDivisions,
            projects,
          } = projectFolder;
          return {
            id,
            name,
            documentCount,
            expirationDate,
            lastModifiedDate,
            ownerNames: owners?.map((owner) => owner.user?.name),
            createdAt,
            documentsDivisions,
            projects,
          };
        })
      );
    }
  }, [data, loading]);

  useEffect(() => {
    if (!data && !loading && error) {
      setProjectFolders([]);
    }
  }, [data, loading, error]);

  useEffect(() => {
    const { filter } = pageLoadParams;

    const variables = {
      filter: { ...filter, onlyCurrentUserDocuments: myTask },
    };

    if (calledCount) {
      refetchCount!(variables);
    } else {
      loadProjectFoldersCount({ variables });
    }
  }, [loadProjectFoldersCount, refetchCount, calledCount, pageLoadParams, myTask]);

  useEffect(() => {
    if (!loadingCount && dataCount) {
      setTotalItems(dataCount.contract_containerCount);
    }
  }, [dataCount, loadingCount]);

  const onFilterChange = useCallback(
    (filterValues: any) => {
      if (pageLoadParams) {
        const newFilter = {
          nameContains: filterValues.name || undefined,
          createdAt: filterValues.createdAt || undefined,
          updatedAt: filterValues.lastModifiedDate || undefined,
          ownerNameContains: filterValues.ownerNames || undefined,

          // todo: api fix required - Relation with property path project in entity was not found
          // projectKeyOrNameContains: filterValues.projects || undefined,

          // todo: api filter
          documentDivisionIds: filterValues.documentsDivisions || undefined,

          onlyUnassignedToProjects: filterValues.onlyUnassignedToProjects,
        };

        if (JSON.stringify(pageLoadParams.filter) !== JSON.stringify(newFilter)) {
          setPageLoadParams((oldPageLoadParams) => ({
            ...oldPageLoadParams,
            page: 0,
            filter: newFilter,
            filterValues,
          }));
          return true;
        }
      }
      return false;
    },
    [pageLoadParams]
  );

  const filterApplied = useMemo(() => {
    const { filter } = pageLoadParams;

    return (
      JSON.stringify(filter) !== '{}' &&
      (!overridePageLoadParams?.filter ||
        JSON.stringify(filter) !== JSON.stringify(overridePageLoadParams?.filter))
    );
  }, [pageLoadParams, overridePageLoadParams?.filter]);

  return {
    totalItems,
    projectFolders,
    loadPage,
    loading: loading || !!loadingCount,
    onFilterChange,
    filterApplied,
    pageLoadParams,
    error: error || errorCounter,
  };
};
