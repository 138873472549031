import { SortOrder } from 'components/ui/Table/components/HeaderCell/HeaderCell';
import { IHeadCell } from 'components/ui/Table/components/TableHead/TableHead';
import { ContainerSortableColumn } from 'graphql/legalFolders/types/graphql-types';
import { TransformDocumentsNumber, TransformOwners } from 'utils/TableTransformFunctions';

export const demacProjectFoldersWithoutProjectsTableHeadExport: IHeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Project Folder Name',
    sortable: true,
    sortBy: ContainerSortableColumn.NAME,
    filter: 'text',
    dataCell: {
      shrink: 'content',
    },
  },
  {
    id: 'documentCount',
    numeric: true,
    disablePadding: false,
    label: 'Documents',
    sortable: false,
    padding: 'half',
    dataCell: {
      shrink: 'content',
      padding: 'half',
      align: 'center',
    },
    transformDataCell: TransformDocumentsNumber,
  },
  {
    id: 'documentsDivisions',
    numeric: false,
    disablePadding: false,
    label: 'Divisions',
    sortable: false,
    // filter: 'dropdown',
    dataCell: {
      shrink: 'content',
      align: 'left',
      wrap: 'box',
    },
    transformFunction: (data, row, order, sorted) => {
      if (!data?.length) return '-';
      if (!sorted) return data.map((item: { name: string }) => item.name).join(', ');
      if (order === SortOrder.ASC) {
        return data
          .map((item: { name: string }) => item.name)
          .sort()
          .join(', ');
      }
      return data
        .map((item: { name: string }) => item.name)
        .sort()
        .reverse()
        .join(', ');
    },
  },
  {
    id: 'ownerNames',
    numeric: false,
    disablePadding: false,
    label: 'Owners',

    dataCell: {
      shrink: 'content',
      align: 'left',
    },

    transformDataCell: TransformOwners,

    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },

    transformFunction: (data, row) => {
      return (
        row?.owners?.map((owner: any) => owner.user.name).join(String.fromCharCode(10)) || '--'
      );
    },
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Created',
    sortable: true,
    sortBy: ContainerSortableColumn.CREATED_AT,
    filter: 'date',
    dataCell: {
      shrink: 'content',
      align: 'left',
    },
    transformFunction: (date) =>
      new Date(date).toLocaleDateString('en-EN', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }),
  },
  {
    id: 'lastModifiedDate',
    numeric: false,
    disablePadding: false,
    label: 'Last Modified',
    sortable: true,
    sortBy: ContainerSortableColumn.UPDATED_AT,
    filter: 'date',
    padding: 'half',
    dataCell: {
      shrink: 'content',
      align: 'left',
    },
    transformFunction: (date, row) => {
      if (!row?.updatedAt) {
        return '--';
      }
      return new Date(row?.updatedAt).toLocaleDateString('en-EN', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
    },
  },
];
