import { ApolloError, useLazyQuery } from '@apollo/client';
import { useTableStorage } from 'components/ui/Table/storage/tableStorageHook';
import { ArchiveFileEventType } from 'constants/enums';
import { DEFAULT_ROWS_PER_PAGE } from 'constants/config';
import { GET_ARCHIVE_FILES, GET_ARCHIVE_FILES_COUNT } from 'graphql/legalFolders/archiveFiles';
import { archiveFileCount } from 'graphql/legalFolders/types/archiveFileCount';
import { archiveFiles } from 'graphql/legalFolders/types/archiveFiles';
import { ArchiveFileFilter } from 'graphql/legalFolders/types/graphql-types';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { IOrderByColumn } from 'components/ui/Table/Table';

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface IArchiveFilesTableHookLoadParams {
  orderByColumns: IOrderByColumn[] | undefined;
  page: number;
  rowsPerPage: number;
  filter?: any;
  filterValues?: any;
}

export interface IArchiveFilesTableHookProps {
  initFilter?: any;
  overridePageLoadParams?: any;
  initPageLoadParams?: Partial<IArchiveFilesTableHookLoadParams>;
  tableStorageKey?: string;
}

export interface IArchiveFilesTableHookValue {
  totalItems: number;
  documents: any;
  loadPage: (
    orderByColumns: IOrderByColumn[] | undefined,
    page: number,
    rowsPerPage: number
  ) => void;
  loading: boolean;
  error: ApolloError | undefined;
  onFilterChange: (filterValues: any) => boolean;
  filterApplied: any;
  pageLoadParams: any;
}

const defaultFilterValues = {
  type: { id: 'All', name: 'All' },
  amount: { id: 'All', name: 'All' },
};

export const convertSelectedToIds = (item: { id: string } | undefined) => {
  return item && item.id !== 'All' ? [item.id] : undefined;
};

export const useArchiveFilesTableHook = ({
  initFilter,
  overridePageLoadParams,
  initPageLoadParams,
  tableStorageKey,
}: IArchiveFilesTableHookProps): IArchiveFilesTableHookValue => {
  const { setItem, getItem } = useTableStorage({
    key: tableStorageKey || 'AllArchiveFilesTable',
  });

  const [totalItems, setTotalItems] = useState(0);
  const [documents, setDocuments] = useState<any[]>([]);
  const [pageLoadParams, setPageLoadParams] = useState<IArchiveFilesTableHookLoadParams>({
    page: 0,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    filter: initFilter,

    ...initPageLoadParams,
    ...getItem(),
  });

  useEffect(() => {
    if (overridePageLoadParams) {
      const { filter } = overridePageLoadParams;
      setPageLoadParams((current) => ({
        ...current,
        ...overridePageLoadParams,
        filter: { ...current.filter, ...filter },
        filterValues: {
          ...defaultFilterValues,
          ...current.filterValues,
          ...overridePageLoadParams.filterValues,
        },
      }));
    }
  }, [overridePageLoadParams]);

  useEffect(() => {
    setItem(pageLoadParams);
  }, [pageLoadParams, setItem]);

  const loadPage = useCallback(
    (orderByColumns: IOrderByColumn[] | undefined, page: number, rowsPerPage: number) => {
      setPageLoadParams((oldPageLoadParams) => ({
        ...oldPageLoadParams,
        orderByColumns,
        page,
        rowsPerPage,
      }));
    },
    []
  );

  const [loadDocuments, { called, data, loading, error, refetch }] = useLazyQuery<archiveFiles>(
    GET_ARCHIVE_FILES,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  const [
    loadDocumentsCount,
    {
      called: calledCount,
      data: dataCount,
      loading: loadingCount,
      error: errorCounter,
      refetch: refetchCount,
    },
  ] = useLazyQuery<archiveFileCount>(GET_ARCHIVE_FILES_COUNT, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    const { rowsPerPage, page, orderByColumns, filter } = pageLoadParams;

    const variables = {
      take: rowsPerPage,
      skip: page * rowsPerPage,
      sort: orderByColumns?.length
        ? orderByColumns.map(({ order, orderBy }) => ({ order, column: orderBy }))
        : undefined,
      filter: { ...filter },
    };

    if (called) {
      refetch!(variables);
    } else {
      loadDocuments({ variables });
    }
  }, [loadDocuments, refetch, called, pageLoadParams]);

  useEffect(() => {
    if (!loading && data) {
      setDocuments(
        data.contract_archiveFiles?.map((archiveFile) => {
          const {
            id,
            blobContainer,
            blobName,
            blobSize,
            mimeType,
            originalFileExtension,
            originalFilePath,
            originalFilename,
            party,
            updatedAt,
            createdAt,
            documentDate,
            events,
            legalFolders,
          } = archiveFile;
          return {
            id,
            blobContainer,
            blobName,
            blobSize,
            mimeType,
            originalFileExtension,
            originalFilePath,
            originalFilename,
            party,
            lastModifiedDate: updatedAt,
            createdAt,
            documentDate,
            partySetEvent:
              party && events.length
                ? events
                    .filter((ev) => ev.eventType === ArchiveFileEventType.ARCHIVE_FILE_PARTY_SET)
                    .sort((ev1, ev2) => (ev1.createdAt > ev2.createdAt ? 1 : -1))[0]
                : undefined,
            documentDateSetEvent:
              documentDate && events.length
                ? events
                    .filter(
                      (ev) => ev.eventType === ArchiveFileEventType.ARCHIVE_FILE_SET_DOCUMENT_DATE
                    )
                    .sort((ev1, ev2) => (ev1.createdAt > ev2.createdAt ? 1 : -1))[0]
                : undefined,
            legalFolders,
          };
        })
      );
    }
  }, [data, loading]);

  useEffect(() => {
    if (!data && !loading && error) {
      setDocuments([]);
    }
  }, [data, loading, error]);

  useEffect(() => {
    const { filter } = pageLoadParams;

    const variables = {
      filter: { ...filter },
    };

    if (calledCount) {
      refetchCount!(variables);
    } else {
      loadDocumentsCount({ variables });
    }
  }, [loadDocumentsCount, refetchCount, calledCount, pageLoadParams]);

  useEffect(() => {
    if (!loadingCount && dataCount) {
      setTotalItems(dataCount.contract_archiveFileCount);
    }
  }, [dataCount, loadingCount]);

  const onFilterChange = useCallback(
    (filterValues: any) => {
      if (pageLoadParams) {
        const blobSizeSelected = convertSelectedToIds(filterValues.blobSize);
        const blobSizePair = blobSizeSelected ? blobSizeSelected[0].split('-') : [null, null];

        const newFilter: ArchiveFileFilter = {
          blobContainerContains: filterValues.blobContainer || undefined,
          blobNameContains: filterValues.blobName || undefined,
          mimeTypes: filterValues.mimeType || undefined,
          originalFileExtensions: filterValues.originalFileExtension || undefined,
          originalFilenameContains: filterValues.originalFilename || undefined,
          originalFilePathContains: filterValues.originalFilePath || undefined,
          blobSizeFrom: blobSizePair[0] ? parseFloat(blobSizePair[0]) : undefined,
          blobSizeTo:
            blobSizePair[1] && blobSizePair[1] !== '0' ? parseFloat(blobSizePair[1]) : undefined,
          partyNameContains: filterValues.party || undefined,
          updatedAt: filterValues.lastModifiedDate || undefined,
          createdAt: filterValues.createdAt || undefined,
          documentDate: filterValues.documentDate || undefined,
          legalFolderNameContains: filterValues.legalFolders || undefined,
        };

        if (JSON.stringify(pageLoadParams.filter) !== JSON.stringify(newFilter)) {
          setPageLoadParams((oldPageLoadParams) => ({
            ...oldPageLoadParams,
            page: 0,
            filter: newFilter,
            filterValues,
          }));
          return true;
        }
      }
      return false;
    },
    [pageLoadParams]
  );

  const filterApplied = useMemo(() => {
    const { filter } = pageLoadParams;
    return (
      JSON.stringify(filter) !== '{}' &&
      (!overridePageLoadParams?.filter ||
        JSON.stringify(filter) !== JSON.stringify(overridePageLoadParams?.filter))
    );
  }, [pageLoadParams, overridePageLoadParams?.filter]);

  return {
    totalItems,
    documents,
    loadPage,
    loading: loading || !!loadingCount,
    onFilterChange,
    filterApplied,
    pageLoadParams,
    error: error || errorCounter,
  };
};
