import { TableCell, TableRow } from '@mui/material';
import cn from 'classnames';
import React, { FC, ReactElement } from 'react';
import { IOrderByColumn } from '../../Table';
import { HeaderCell, SortOrder } from '../HeaderCell/HeaderCell';
import s from './style.module.scss';

interface IItem {
  id: string;
  name: string;
}

export interface IHeadCell {
  cellType?: 'input' | 'divider';
  disablePadding: boolean;
  id: string;
  label: string;
  inputLabel?: string;
  numeric: boolean;
  sortable?: boolean;
  sortBy?: string;
  align?: 'left' | 'center' | 'right';
  padding?: 'half';
  dataCell?: {
    align?: 'left' | 'center' | 'right';
    wrap?: 'nowrap' | 'box';
    connected?: 'left' | 'middle' | 'right';
    shrink?: 'content';
    padding?: 'half';
  };
  transformDataCell?: (
    data: any,
    row?: any,
    order?: SortOrder,
    sorted?: boolean
  ) => ReactElement<any, any> | null;
  transformFunction?: (
    data: any | undefined,
    row?: any,
    order?: SortOrder,
    sorted?: boolean
  ) => any;
  filter?:
    | 'text'
    | 'dropdown'
    | 'date'
    | 'mobile-date'
    | 'component'
    | 'hierarchy-multiple'
    | 'text-dropdown'
    | 'date-range';
  filterProps?: any;
  filterComponent?: ({
    onSelectChange,
    selectedItem,
  }: {
    onSelectChange?: (selectedItem: IItem) => void;
    selectedItem?: IItem | null;
    [key: string]: any;
  }) => ReactElement<any, any> | null;
  colSpan?: number;
  excelHeadStyle?: any;
  excelRowStyle?: any;
}

export interface TableHeadProps {
  headCells: IHeadCell[];
  orderByColumns: IOrderByColumn[] | undefined;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onRequestSetSort: (
    x: { sort: SortOrder; sortOrder: number } | undefined,
    property: string
  ) => void;
  printView?: boolean;
  stickyHeader?: boolean;
}

export const TableHead: FC<TableHeadProps> = ({
  orderByColumns,
  headCells,
  onRequestSort,
  onRequestSetSort,
  printView,
  stickyHeader,
}) => {
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  const createSetSortHandler =
    (property: string) => (x: { sort: SortOrder; sortOrder: number } | undefined) => {
      onRequestSetSort(x, property);
    };
  let count = -1;
  return (
    <TableRow>
      {headCells.map((headCell) => {
        count++;
        const index = orderByColumns?.findIndex(
          ({ orderBy }) => orderBy === headCell.sortBy || orderBy === headCell.id
        );
        return (
          <TableCell
            key={count.toString() + '_' + headCell.id}
            className={cn({ [s.paddingHalf]: headCell.padding === 'half' })}
            style={stickyHeader ? { backgroundColor: 'white' } : undefined}
          >
            <div>
              <HeaderCell
                key={headCell.id}
                label={headCell.label}
                sortable={headCell.sortable && (!printView || (orderByColumns && index! >= 0))}
                sort={orderByColumns && index! >= 0 ? orderByColumns[index!].order : undefined}
                sortOrder={
                  orderByColumns && orderByColumns?.length && index! >= 0 ? index! + 1 : undefined
                }
                onSortChange={createSortHandler(headCell.sortBy || headCell.id)}
                orderLength={orderByColumns ? orderByColumns.length : 0}
                onSetSort={createSetSortHandler(headCell.sortBy || headCell.id)}
              ></HeaderCell>
            </div>
          </TableCell>
        );
      })}
    </TableRow>
  );
};
