import { Box, Button, Divider, Grid } from '@mui/material';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { FC, useMemo } from 'react';
import { SelectLegalFolder } from 'components/SelectLegalFolder/SelectLegalFolder';
import { SelectProjectFolder } from 'components/SelectProjectFolder/SelectProjectFolder';
import { Asterisks } from 'components/Asterisks/Asterisks';

interface IItem {
  id: string;
  name: string;
}

export interface IMoveDocumentProps {
  currentContainer?: { id: string; name: string; legalFolder: { id: string; name: string } } | null;
  onSelectLegalFolderChange?: (selectedItem: IItem) => void;
  selectedLegalFolder?: IItem | null;
  onSelectProjectFolderChange?: (selectedItem: IItem) => void;
  selectedProjectFolder?: IItem | null;
  [key: string]: any;
  cancelHandler: () => void;
  confirmHandler: () => void;
}

export const MoveDocument: FC<IMoveDocumentProps> = ({
  currentContainer,
  onSelectLegalFolderChange,
  selectedLegalFolder,
  onSelectProjectFolderChange,
  selectedProjectFolder,
  cancelHandler,
  confirmHandler,
}) => {
  const disabledSubmit = useMemo(
    () =>
      !selectedProjectFolder ||
      (!!currentContainer && currentContainer.id === selectedProjectFolder?.id),
    [selectedProjectFolder, currentContainer]
  );

  return (
    <>
      <Grid container style={{ width: '100%' }} spacing={2}>
        <Grid item xs={12}>
          <p>
            To move the Document, please select Legal Folder and then Project Folder inside Legal
            Folder.
          </p>
          <p>Document will be moved together with all assigned files.</p>
          <p>
            Links to parent documents will not be transferred to the new location. Connections to
            parent documents will be lost and need to be re-established manually after the move.
          </p>
          <p>
            If the document is associated with any projects, these projects will be added to the
            target project folder if they are not already present there.
          </p>
        </Grid>
      </Grid>

      <Box m={2} />

      <Grid container style={{ width: '100%' }} spacing={2}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Divider />
            <Box m={4} />
          </Grid>
        </Grid>
      </Grid>

      <Grid container style={{ width: '100%' }} spacing={2}>
        <Grid item xs={4} md={3}>
          Current Legal Folder:
        </Grid>
        <Grid item xs={8} md={9}>
          {currentContainer?.legalFolder.name}
        </Grid>
        <Grid item xs={4} md={3}>
          Select target legal folder
          <Asterisks count={1} />:
        </Grid>
        <Grid item xs={8} md={9}>
          <SelectLegalFolder
            placeholder="Select Legal Folder"
            onSelectChange={onSelectLegalFolderChange}
            selectedItem={selectedLegalFolder}
          ></SelectLegalFolder>
        </Grid>
      </Grid>

      <Box m={4} />

      <Grid container style={{ width: '100%' }} spacing={2}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Divider />
            <Box m={4} />
          </Grid>
        </Grid>
      </Grid>

      <Grid container style={{ width: '100%' }} spacing={2}>
        <Grid item xs={4} md={3}>
          Current Project Folder:
        </Grid>
        <Grid item xs={8} md={9}>
          {currentContainer?.name}
        </Grid>
        <Grid item xs={4} md={3}>
          Select target project folder
          <Asterisks count={2} />:
        </Grid>
        <Grid item xs={8} md={9}>
          <SelectProjectFolder
            key={`LegalFolder-${currentContainer?.legalFolder?.id}-ProjectFolders`}
            placeholder="Select Project Folder"
            onSelectChange={onSelectProjectFolderChange}
            selectedItem={selectedProjectFolder}
            legalFolderId={selectedLegalFolder?.id}
          ></SelectProjectFolder>
        </Grid>
      </Grid>

      <Box m={4} />

      <Grid container style={{ width: '100%' }} spacing={2}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Divider />
            <Box m={4} />
          </Grid>
        </Grid>
      </Grid>

      <Grid container style={{ width: '100%' }} spacing={2}>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', gap: 20 }}>
          <Button
            color={'primary'}
            variant="contained"
            size="medium"
            onClick={debounce((): void => {
              confirmHandler();
            }, DEBOUNCE_TIMEOUT)}
            style={{
              fontSize: '0.85rem',
              ...(!disabledSubmit ? { background: 'red', color: 'white' } : {}),
            }}
            disabled={disabledSubmit}
          >
            Move Document
          </Button>

          <Button
            color={'secondary'}
            variant="contained"
            size="medium"
            onClick={debounce((): void => {
              cancelHandler();
            }, DEBOUNCE_TIMEOUT)}
            style={{ fontSize: '0.85rem' }}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={12}>
          <p>
            <Asterisks count={1} /> Please select Legal Folder first and then Project Folder inside
            Legal Folder.
          </p>
          <p>
            <Asterisks count={2} />
            Only Project Folders inside selected Legal Folder are available.
          </p>
        </Grid>
      </Grid>
    </>
  );
};
