import { Box } from '@mui/material';
import { Table } from 'components/ui/Table/Table';
import { TABLE_PAGINATION } from 'constants/config';
import { FC } from 'react';

export interface IDataSourceTableProps {
  dataSource: any;
  tableHead: any;
  onSelect: (id: string) => boolean;
}

export const DataSourceTable: FC<IDataSourceTableProps> = ({ dataSource, tableHead, onSelect }) => {
  const {
    totalItems,
    items,
    loadPage,
    filterOptions,
    pageLoadParams,
    defaultFilterValues,
    onFilterChange,
  } = dataSource;

  return (
    <Box
      style={{
        borderRadius: '1px',
        boxShadow: '0px 2px 5px #00000030',
        border: '1px solid #00000030',
      }}
    >
      <Table
        totalItems={totalItems}
        dataCells={items}
        headCells={tableHead}
        loadPage={loadPage}
        handleSelect={onSelect}
        filterOptions={filterOptions}
        filterValues={pageLoadParams.filterValues || defaultFilterValues}
        onFilterChange={onFilterChange}
        initRowsPerPage={pageLoadParams.rowsPerPage}
        paginationProps={{
          rowsPerPageOptions: TABLE_PAGINATION.includes(5)
            ? TABLE_PAGINATION
            : [5, ...TABLE_PAGINATION],
        }}
        initOrderByColumns={pageLoadParams.orderByColumns}
        initPage={pageLoadParams.page}
      ></Table>
    </Box>
  );
};
