import { useApolloClient } from '@apollo/client';
import { TYPING_TIMEOUT } from 'constants/config';
import { GET_PARTIES_UNIQUE } from 'graphql/legalFolders/parties';
import { getPartiesUnique } from 'graphql/legalFolders/types/getPartiesUnique';
// import { Order, PartySortableColumn } from 'graphql/proposals/types/graphql-types';
import { pick } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const MIN_WORD_LENGTH = 3;
const SKIP_WORDS = ['or', 'and'];
const RESULTS_PER_WORD = 100;

export interface IPartiesFuzzySearchProps {
  searchString: string;
  countryId?: string;
  excludeIds: Array<string>;
}

export interface IPartiesFuzzySearchResult {
  grade: grades;
  search: string;
  cache: IResultSetCache;
}

export interface IResultSet {
  found: Array<any>;
}

export interface IResultSetCache {
  [word: string]: IResultSet;
}

// -3	- exact match
// -1	- found whole search string as substring
// 1	- found partialy matched whole search string
// 2	- any match
// 3	- default

export enum grades {
  WHOLE_SEARCH_STRING_MATCH = 'WHOLE_SEARCH_STRING_MATCH',
  EXACT_MATCH_FOUND = 'EXACT_MATCH_FOUND',
  WHOLE_SEARCH_STRING_AS_SUBSTRING = 'WHOLE_SEARCH_STRING_AS_SUBSTRING',
  PARTIAL_SEARCH_STRING_AS_SUBSTRING1 = 'PARTIAL_SEARCH_STRING_AS_SUBSTRING1',
  PARTIAL_SEARCH_STRING_AS_SUBSTRING2 = 'PARTIAL_SEARCH_STRING_AS_SUBSTRING2',
  ANY_MATCH3 = 'ANY_MATCH3',
  ANY_MATCH2 = 'ANY_MATCH2',
  ANY_MATCH1 = 'ANY_MATCH1',
  FULL_GREEN = 'FULL_GREEN',
  IGNORE = 'IGNORE',
}

export const usePartiesFuzzySearch = ({
  searchString,
  excludeIds = [],
  countryId,
}: IPartiesFuzzySearchProps): IPartiesFuzzySearchResult => {
  const [search, setSearch] = useState('');
  const client = useApolloClient();
  const [cache, setCache] = useState<IResultSetCache>({});

  const searchRef = useRef(search);
  useEffect(() => {
    searchRef.current = search;
  }, [search]);

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const changeSearchString = useCallback((searchString: string) => {
    if (timer.current) {
      clearInterval(timer.current);
    }
    timer.current = setTimeout(() => {
      setSearch(searchString.toLowerCase().replace(/\s\s+/g, ' ').trim());
    }, TYPING_TIMEOUT);
  }, []);

  useEffect(() => {
    changeSearchString(searchString);
  }, [searchString, changeSearchString]);

  const loadUnique = useCallback(
    async (word: string) => {
      const variables = {
        take: RESULTS_PER_WORD,
        filter: {
          nameContains: word,
          isActive: true,
        },
        // sort: [{ column: PartySortableColumn.NAME, order: Order.ASC }],
      };
      let result = {};
      try {
        result = {
          ...(await client.query<getPartiesUnique>({
            query: GET_PARTIES_UNIQUE,
            variables: { ...variables },
            fetchPolicy: 'network-only',
          })),
        };
      } catch (ex: any) {
        result = { ...ex };
      }
      return result;
    },
    [client]
  );

  const process = useCallback(async () => {
    const words = search.split(' ');

    // If one word it is already whole search string
    if (words.length > 1) {
      words.push(search);
    }

    let searchCache = { ...cache };
    let newResultSet: IResultSetCache = {};

    for (var i = 0; i < words.length && searchRef.current === search; i++) {
      const word = words[i];

      if (word.length >= MIN_WORD_LENGTH && !SKIP_WORDS.includes(word.toLowerCase())) {
        // Reuse word and two subwords from the cache
        const pickList = [
          word,
          word.substring(0, word.length - 1),
          word.substring(0, word.length - 2),
        ];
        newResultSet = { ...newResultSet, ...pick(searchCache, pickList) };

        // If word is in the cache, next
        if (newResultSet[word]?.found.length) {
          continue;
        }

        let wordPart = word;
        let fuzzyCount = 0;
        while (
          wordPart.length >= MIN_WORD_LENGTH &&
          !SKIP_WORDS.includes(wordPart) &&
          fuzzyCount < 3
        ) {
          if (!searchCache[wordPart]) {
            const partResults: any = await loadUnique(wordPart);
            const list = partResults.data?.parties.filter(
              (party: { id: string }) => !excludeIds.includes(party.id)
            );
            if (list?.length) {
              newResultSet[wordPart] = { found: [...list] };
              searchCache[wordPart] = { found: [...list] };
            } else {
              newResultSet[wordPart] = { found: [] };
              searchCache[wordPart] = { found: [] };
            }
          }

          fuzzyCount++;
          wordPart = wordPart.substring(0, wordPart.length - 1);
        }
      }
    }
    if (searchRef.current === search) {
      setCache(newResultSet);
    }
  }, [loadUnique, cache, search, excludeIds]);

  const grade: grades = useMemo(() => {
    const search = searchRef.current;

    if (search.length < 4) {
      return grades.IGNORE;
    }

    const exactMatchResult = cache[search]?.found.find(
      (item) => item.name.toLowerCase() === search && item.country?.id === countryId
    );
    if (!!exactMatchResult) {
      return grades.EXACT_MATCH_FOUND;
    }

    if (cache[search]?.found.find((item) => item.name.toLowerCase() === search)) {
      return grades.WHOLE_SEARCH_STRING_MATCH;
    }

    if (cache[search]?.found.find((item) => item.name.toLowerCase().indexOf(search) >= 0)) {
      return grades.WHOLE_SEARCH_STRING_AS_SUBSTRING;
    }

    if (cache[search.substring(0, search.length - 1)]?.found.length) {
      return grades.PARTIAL_SEARCH_STRING_AS_SUBSTRING1;
    }

    if (cache[search.substring(0, search.length - 2)]?.found.length) {
      return grades.PARTIAL_SEARCH_STRING_AS_SUBSTRING2;
    }

    const words = search.split(' ');
    const foundKeys = words.filter((key) => cache[key]?.found.length);

    if (foundKeys.length >= words.length) {
      return grades.ANY_MATCH3;
    }

    if (foundKeys.length >= (words.length * 2) / 3) {
      return grades.ANY_MATCH2;
    }

    if (foundKeys.length >= words.length / 3) {
      return grades.ANY_MATCH1;
    }

    return grades.FULL_GREEN;
  }, [cache, countryId]);

  const processRef = useRef(process);

  useEffect(() => {
    processRef.current = process;
  }, [process]);

  useEffect(() => {
    processRef.current();
  }, [search]);

  return {
    grade,
    search,
    cache,
  };
};
