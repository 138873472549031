import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IArchiveFileData } from './components/ArchiveFile/interface';
import { useMutation } from '@apollo/client';
import {
  ARCHIVE_FILE_SET_PARTY,
  ARCHIVE_FILE_UNSET_PARTY,
  ARCHIVE_FILE_UPDATE,
} from 'graphql/legalFolders/archiveFiles';
import { tryUpdateProcedure } from 'utils/apollo';
import { ArchiveFileSetPartyFilter } from 'graphql/legalFolders/types/graphql-types';

export interface IArchiveFileContextState {
  archiveFile?: IArchiveFileData;
  changed: boolean;
}

export interface IArchiveFileContextActions {
  setArchiveFile: any;
  submitSetParty: any;
  submitUnsetParty: any;
  onSubmit: any;
  refetch: any;
}

const initialState: IArchiveFileContextState = {
  archiveFile: undefined,
  changed: false,
};

const ArchiveFileContext = createContext<
  IArchiveFileContextState & Partial<IArchiveFileContextActions>
>(initialState);

interface IArchiveFileProviderProps {
  children: any;
  loadedArchiveFile?: IArchiveFileData;
  refetch?: any;
  viewOnly?: boolean;
}

export const ArchiveFileProvider: FC<IArchiveFileProviderProps> = ({
  children,
  loadedArchiveFile,
  refetch,
  viewOnly,
}) => {
  const [archiveFile, setArchiveFile] = useState<IArchiveFileData>();
  const [setPartyMutation] = useMutation(ARCHIVE_FILE_SET_PARTY);
  const [unsetPartyMutation] = useMutation(ARCHIVE_FILE_UNSET_PARTY);
  const [updateArchiveFile] = useMutation(ARCHIVE_FILE_UPDATE);

  useEffect(() => {
    if (loadedArchiveFile) {
      setArchiveFile({
        ...loadedArchiveFile,
        canUpdate: loadedArchiveFile.canUpdate && !viewOnly,
        selectedParty: loadedArchiveFile.party
          ? {
              key: loadedArchiveFile.party.id,
              name: loadedArchiveFile.party.name,
              ddLabel:
                (loadedArchiveFile.party.projectSetupClientCode ||
                  (loadedArchiveFile.party.partyType?.name === 'CLIENT'
                    ? 'Client'
                    : 'Non-Client')) +
                ' - ' +
                loadedArchiveFile.party.name,
            }
          : undefined,
      });
    }
  }, [loadedArchiveFile, viewOnly]);

  const submitSetParty = useCallback(
    async (applyToRootFolder: boolean) => {
      if (!archiveFile) {
        return;
      }
      const { selectedParty } = archiveFile;

      let filter: ArchiveFileSetPartyFilter = {};
      const baseDirectory = archiveFile.originalFilePath.split('/')[0];
      if (!applyToRootFolder) {
        filter.archiveFileIds = [archiveFile.id];
      } else {
        filter.baseDirectory = baseDirectory;
      }

      const { result, isError, errors } = await tryUpdateProcedure({
        mutation: () =>
          setPartyMutation({
            variables: {
              partyId: selectedParty?.key,
              filter,
            },
          }),
        parseResult: (data: any) => {
          return data;
        },
      });
      return { result, isError, errors };
    },
    [archiveFile, setPartyMutation]
  );

  const submitUnsetParty = useCallback(
    async (applyToRootFolder: boolean) => {
      if (!archiveFile) {
        return;
      }

      let filter: ArchiveFileSetPartyFilter = {};
      const baseDirectory = archiveFile.originalFilePath.split('/')[0];
      if (!applyToRootFolder || !baseDirectory) {
        filter.archiveFileIds = [archiveFile.id];
      } else {
        filter.baseDirectory = baseDirectory;
      }

      const { result, isError, errors } = await tryUpdateProcedure({
        mutation: () =>
          unsetPartyMutation({
            variables: {
              filter,
            },
          }),
        parseResult: (data: any) => {
          return data;
        },
      });
      return { result, isError, errors };
    },
    [archiveFile, unsetPartyMutation]
  );

  const onSubmit = useCallback(async () => {
    if (!archiveFile) {
      return { isError: true, errors: ['Missing Pre-LMS file data'] };
    }

    const { id, documentDate } = archiveFile;

    // check if any change
    if (documentDate === loadedArchiveFile?.documentDate) {
      return { isError: false };
    }

    const { result, isError, errors } = await tryUpdateProcedure({
      mutation: () =>
        updateArchiveFile({
          variables: {
            id,
            documentDate,
          },
        }),
      parseResult: (data: any) => {
        return data;
      },
    });
    return { result, isError, errors };
  }, [archiveFile, updateArchiveFile, loadedArchiveFile]);

  const changed = useMemo(() => {
    if (!archiveFile || !loadedArchiveFile) {
      return false;
    }

    if (archiveFile.documentDate !== loadedArchiveFile.documentDate) {
      return true;
    }

    return false;
  }, [archiveFile, loadedArchiveFile]);

  return (
    <ArchiveFileContext.Provider
      value={{
        archiveFile,
        setArchiveFile,
        submitSetParty,
        submitUnsetParty,
        onSubmit,
        refetch,
        changed,
      }}
    >
      {children}
    </ArchiveFileContext.Provider>
  );
};

export const useArchiveFileContext = () => useContext(ArchiveFileContext);
